import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";

const header = {
  ...allHeaders["restfulWebserviceUsingCXF"],
  hashTags: ["java", "springboot", "react", "webservices", "spring"],
  hashTagColors: ["blue", "warning", "info", "danger", "success"],
  channel: "6"
};

const data = [
  {
    type: "subheading",
    name: "restful-webservice-using-cxf",
    title: "WebService"
  },
  {
    type: "text",
    content:
      "Any service which follows the REST (REpresentational State Transfer) architectural pattern is said to be restful. " +
      "Let us look at an example on how to create a simple restful webservice using CXF and Spring. The following software has been used for this tutorial : " +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "1. Apache Maven 3.0.4<br /> " +
      "2. Spring 3.2.6<br /> " +
      "3. Apache CXF 3.0.3<br /> " +
      "4. Jetty 6.1.10/Tomcat 7 " +
      "<div class='lineBreak' /> " +
      "First, we need to create a basic CXF web project structure. For this, use the below command : " +
      "<div class='lineBreak' /> " +
      "<code style='white-space: normal'>mvn archetype:generate -DgroupId=com.learninjava -DartifactId=cxfjaxrsws<br /> " +
      "-DarchetypeArtifactId=org.apache.cxf.archetype:cxf-jaxrs-service -DinteractiveMode=false</code> " +
      "<div class='lineBreak' /> "
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "1. If the archetype is not found due to future maven changes, you can simply use the below command : " +
          "<div class='lineBreakSmall' /> " +
          "<code>mvn archetype:generate</code> " +
          "<div class='lineBreakSmall' /> " +
          "Now, select the appropriate archetype serial number from the list provided. On our development environment, the serial number for our tutorial is, " +
          "<div class='lineBreakSmall' /> " +
          "<i>514: remote -> org.apache.cxf.archetype:cxf-jaxrs-service (Simple CXF JAX-RS webapp service using Spring configuration)</i> " +
          "<div class='lineBreakSmall' /> " +
          "2. You can actually create a restful webservice without even writing a single line of code. " +
          "<div class='lineBreakSmall' /> " +
          "See here : " +
          "<a class='homelink' href='http://java-hub.blogspot.in/2015/01/restful-webservice-using-cxf-and-maven.html' target='_blank'>restful-webservice-using-cxf-and-maven</a>" +
          "<div class='lineBreakSmall' />" +
          "Remember, you will just get an unpolished version of the project with out concepts being explained. So, we recommend going through the below tutorial."
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "If the command is successful, your basic project structure should look similar to the below : " +
      "<div class='lineBreak' /> " +
      "<img src='https://github.com/learninjavagithub/assets/raw/master/restful-webservice-project-structure.JPG' alt='restful-webservice-project-structure' />"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "You CANNOT use the Test case files provided by this archetype without modification because if you do so, you cannot run the unit " +
          "test file (client). This is because the maven surefire plugin expects the test file names in the following patterns : " +
          "<div class='lineBreakSmall' /> " +
          "**/Test*.java<br /> " +
          "**/*Test.java<br /> " +
          "**/*TestCase.java<br /> " +
          "<div class='lineBreakSmall' /> " +
          "but the archetype creates the Test case file as HelloWorldIT.java"
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Maven does not consider this file (HelloWorldIT.java) as a test case as there is no 'Test' string in the file name. For this purpose, we have " +
      "renamed the test file to HelloAngryWorldTest.java to make sure the junit tests are run properly and also there were many changes made " +
      "to pom.xml. For instance, we have removed some unused configurations and refactored the configurations for easy and better understanding." +
      "<div class='lineBreak' /> " +
      "For your convenience, we have provided configurations for running the webservice on both Jetty and Tomcat servers." +
      "<div class='lineBreak' /> " +
      "First, let us take a look at tomcat configuration. Later we will see about jetty configuration. There is a cool feature in jetty that attracts " +
      "many developers. we will see what is it later in this tutorial."
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "using-tomcat",
    title: "Using Tomcat :"
  },
  {
    type: "text",
    content: "<h4>1. Change the default pom.xml as below :</h4>"
  },
  {
    type: "gist",
    content: "bd5ea7f985fc3cdee14cda2698d775b5"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "You will notice that there are 4 important plugins in the pom.xml out of which 2 are optional. We will see each of them in detail : " +
      "<div class='lineBreakSmall' /> " +
      "<b>maven-surefire-plugin</b> - [Optional] This plugin is added to specify the service.url in the pom.xml itself. We will see its usage while running the web service client." +
      "<div class='lineBreakSmall' /> " +
      "<b>tomcat7-maven-plugin</b> - This is the tomcat plugin where will deploy our angry world webservice." +
      "<div class='lineBreakSmall' /> " +
      "<b>maven-compiler-plugin</b> - This plugin specifies the Java compiler source and target versions." +
      "<div class='lineBreakSmall' /> " +
      "<b>maven-eclipse-plugin</b> - [Optional] This plugin is used to generate the .classpath and .project files which enables us to use this project in eclipse." +
      "<div class='lineBreakSmall' /> " +
      "The other important sections includes dependencies and properties which are used to specify the jar file dependencies and properties respectively." +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content: "<h4>2. Renamed HelloWorld.java to HelloAngryWorld.java :</h4>"
  },
  {
    type: "gist",
    content: "9b1a763a390575a5a1afa97c29a481b3"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "This is the actual webservice source file, so lets take a look at it." +
      "<div class='lineBreakSmall' /> " +
      "This webservice exposes two methods one of type plain text and other of type JsonBean. These methods uses some important annotations: " +
      "<div class='lineBreakSmall' /> " +
      "<b>@Path</b> - annotation at class level specifies the context path to access this webservice." +
      "<div class='lineBreakSmall' /> " +
      "<b>@Path</b> - annotation at method level specifies the path to access the actual method in the webservice." +
      "<div class='lineBreakSmall' /> " +
      "<b>@PathParam</b> - annotation is used to accept input from the request URL path specified by @Path." +
      "<div class='lineBreakSmall' /> " +
      "<b>@Produces</b> - annotation is used to specify the return type of the method." +
      "<div class='lineBreakSmall' /> " +
      "<b>@Consumes</b> - annotation is used to specify the return type accepted as input to a method." +
      "<div class='lineBreakSmall' /> " +
      "For a complete listing of annotations refer : <a class='homelink' href='http://java-hub.blogspot.in/2015/01/jax-rs-annotations.html' target='_blank'>jax-rs-annotations</a>" +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content: "<h4>3. JsonBean.java :</h4>"
  },
  {
    type: "gist",
    content: "7c425906b6cd84a65e498204ae3bc8ea"
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h4>4. beans.xml :</h4>"
  },
  {
    type: "gist",
    content: "1c901f53a50b63d69317540022df24f1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "<code>PreferencesPlaceholderConfigurer</code> and <code>ServletContextPropertyPlaceholderConfigurer</code> are used to handle properties files. See references section at the bottom of this tutorial for more information." +
      "The beans.xml has 2 important tags under &lt;jaxrs:server&gt; tag which is the most important tag in this xml." +
      "<div class='lineBreakSmall' />" +
      "<b>&lt;jaxrs:serviceBeans&gt;</b> - This lists all the service beans which are nothing but the services we want to make as a webservice" +
      "<div class='lineBreakSmall' />" +
      "<b>&lt;jaxrs:providers&gt;</b> - This is to specify the provider which are used to extend the JAX-RS runtime. There are 3 types of providers: Entity providers, Context providers and Exception providers. Here Jackson is an alternative provider is used. Refer to the JAX-RS specification link provided in references section for more info. Please note that this is an optional configuration as there is already a default Json provider included with JAX-RS called Jettison (org.apache.cxf.jaxrs.provider.json.JSONProvider)." +
      "<div class='lineBreakSmall' />" +
      "Observe that names of the entities like service, server etc. These are in conjunction with REST architecture. You will find these names to be different when dealing with JAX-WS"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h4>5. web.xml :</h4>"
  },
  {
    type: "gist",
    content: "9d576700576054c155f6302939cd5326"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "The configurations in web.xml are straighforward. CXFServlet is the actual servlet that reads the xml configurations and makes the service beans as webservices and handles the requests and responses to the exposed services." +
      "<div class='lineBreakSmall' />" +
      "ContextLoaderListener is the ROOT web application context that is used by spring." +
      "<div class='lineBreakSmall' />" +
      "Once the above files are ready, type the below command to start the tomcat container :" +
      "<div class='lineBreakSmall' />" +
      "<code style='white-space: normal'>mvn clean package -DskipTests=true tomcat7:run</code>" +
      "<div class='lineBreakSmall' />" +
      "This command cleans the target directory, compiles, builds war and deploys it to tomcat. Notice that we used skipTests = true switch. This is to make sure that the test case are not run before the server starts." +
      "<div class='lineBreakSmall' />" +
      "Navigate to the URL :" +
      "<div class='lineBreakSmall' />" +
      "<i>http://localhost:9090/jaxrs-service/helloAngryWorld/echo/tweet</i>"
  },
  {
    type: "output",
    content: "\rtweet\r\n"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "This is an echo service so whatever you input in the URL should be echoed to your browser." +
      "<div class='lineBreakSmall' />" +
      "If you are unware of your endpoint address, you can use the below url to find out," +
      "<div class='lineBreakSmall' />" +
      "<i>http://localhost:9090/jaxrs-service/cxf/services</i>" +
      "<div class='lineBreakSmall' />" +
      "This lists all the REST and SOAP services running on your server."
  },
  {
    type: "googleAd",
    position: "middle4"
  },
  {
    type: "subheading",
    name: "using-jetty",
    title: "Using Jetty :"
  },
  {
    type: "text",
    content: "<h4>1. pom.xml :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "The pom.xml is pretty much similar to the one we have used for tomcat configuration except that the tomcat plugin is replaced with the jetty equivalent. Also the port number is changed 8080 just to distinguish between tomcat and jetty."
  },
  {
    type: "gist",
    content: "48d23bb72519f8c980c99c15369bc9ce"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "All other steps are same as above." +
      "<div class='lineBreakSmall' />" +
      "To start the jetty container and the webservice use the below command :" +
      "<div class='lineBreakSmall' />" +
      "<code style='white-space: normal'>mvn clean package -DskipTests=true jetty:run</code>" +
      "<div class='lineBreakSmall' />" +
      "Navigate to the URL :" +
      "<div class='lineBreakSmall' />" +
      "<i>http://localhost:8080/jaxrs-service/helloAngryWorld/echo/tweet</i>"
  },
  {
    type: "output",
    content: "\rtweet\r\n"
  },
  {
    type: "subheading",
    name: "advantages-of-using-jetty",
    title: "Advantage of using Jetty - Hot code deployment :"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "By default, Jetty scans the following directories :" +
      "<div class='lineBreakSmall' />" +
      "<b>target/classes</b> - for changes to Java sources and" +
      "<div class='lineBreakSmall' />" +
      "<b>src/main/webapp</b> - for changes to web sources." +
      "<div class='lineBreakSmall' />" +
      "If any source file is modified, the changes are autodeployed to the jetty container without manually restarting for the changes to take effect." +
      "<div class='lineBreakSmall' />" +
      "To test this hot code deployment, try to change any source file. We have changed the Path param of ping method from echo to ping. Now, recompiled the source using <code>mvn compile</code> command." +
      "<div class='lineBreakSmall' />" +
      "The moment the source file is compiled, notice the server console. You will observe that jetty hot deploys the code automatically and you can access the changes via browser instantly." +
      "<div class='lineBreakSmall' />" +
      "Navigate to the URL :" +
      "<div class='lineBreakSmall' />" +
      "<i>http://localhost:8080/jaxrs-service/helloAngryWorld/ping/tweet</i>"
  },
  {
    type: "output",
    content: "\rtweet\r\n"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/restful-client-using-cxf/",
    previousText: "JAX-RS Restful Client using CXF",
    nextURL: "/restful-client-using-jersey/",
    nextText: "JAX-RS Restful Client using Jersey"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/cxfjaxrsws.zip"
  }
];

class RestfulWebserviceUsingCXF extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default RestfulWebserviceUsingCXF;
