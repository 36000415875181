import HomePage from "components/layouts/HomePage.jsx";
import Stream from "components/Articles/Java8/Stream.jsx";
import FunctionalInterfaces from "components/Articles/Java8/FunctionalInterfaces.jsx";
import Search from "components/Templates/Search.jsx";
import Contact from "components/Templates/Contact.jsx";
import EqualsAndHashCodeOverride from "components/Articles/Java/EqualsAndHashCodeOverride.jsx";
import RestfulWebserviceUsingCXF from "components/Articles/Webservices/RestfulWebserviceUsingCXF.jsx";
import ThreadStates from "components/Articles/Java/ThreadStates.jsx";
import LambdaExpressions from "components/Articles/Java8/LambdaExpressions.jsx";
import UserThreadDaemonThread from "components/Articles/Java/UserThreadDaemonThread.jsx";
import RestfulClientUsingCXF from "components/Articles/Webservices/RestfulClientUsingCXF.jsx";
import SOAPWebserviceUsingCXF from "components/Articles/Webservices/SOAPWebserviceUsingCXF.jsx";
import SOAPClientUsingCXF from "components/Articles/Webservices/SOAPClientUsingCXF.jsx";
import RestfulWebserviceUsingJersey from "components/Articles/Webservices/RestfulWebserviceUsingJersey.jsx";
import RestfulClientUsingJersey from "components/Articles/Webservices/RestfulClientUsingJersey.jsx";
import ReactRoutingConfig from "components/Articles/React/ReactRoutingConfig.jsx";
import CRAReactRoutingConfig from "components/Articles/React/CRAReactRoutingConfig.jsx";
import ReactWidgetPackDisqusComments from "components/Articles/React/ReactWidgetPackDisqusComments.jsx";
import AngularRoutingConfig from "components/Articles/Angular/AngularRoutingConfig.jsx";
import AngularRoutingConfigCLIApp from "components/Articles/Angular/AngularRoutingConfigCLIApp.jsx";
import ReactProxyConfig from "components/Articles/React/ReactProxyConfig.jsx";
import ReactReduxConfig from "components/Articles/React/ReactReduxConfig.jsx";
import SOAPMessageToObject from "components/Articles/Java/SOAPMessageToObject.jsx";
import ReactTableWithPagination from "components/Articles/React/ReactTableWithPagination.jsx";
import ReactLoadingSpinnerWithOverlay from "components/Articles/React/ReactLoadingSpinnerWithOverlay.jsx";
import ReactTableWithLoadingSpinner from "components/Articles/React/ReactTableWithLoadingSpinner.jsx";
import WebClientCRUD from "components/Articles/Webservices/WebClientCRUD.jsx";
import WebClientTesting from "components/Articles/Webservices/WebClientTesting.jsx";
import ReactTableWithRecordCount from "components/Articles/React/ReactTableWithRecordCount.jsx";
import ReactTableWithNestedSubTable from "components/Articles/React/ReactTableWithNestedSubTable.jsx";
import EasyGuideToSSL from "components/Articles/Java/EasyGuideToSSL.jsx";
import PKCSAndPEMToJKS from "components/Articles/Java/PKCSAndPEMToJKS.jsx";
import TLSMutualAuth from "components/Articles/Java/TLSMutualAuth.jsx";

const Routes = [
  {
    path: "/",
    component: HomePage
  },
  {
    path: "/home",
    component: HomePage
  },
  {
    path: "/java8-stream",
    component: Stream
  },
  {
    path: "/java8-functional-interfaces",
    component: FunctionalInterfaces
  },
  {
    path: "/why-equals-and-hashcode-override",
    component: EqualsAndHashCodeOverride
  },
  {
    path: "/restful-webservice-using-cxf",
    component: RestfulWebserviceUsingCXF
  },
  {
    path: "/thread-states",
    component: ThreadStates
  },
  {
    path: "/contact",
    component: Contact
  },
  {
    path: "/java8-lambda-expressions",
    component: LambdaExpressions
  },
  {
    path: "/user-thread-and-daemon-thread",
    component: UserThreadDaemonThread
  },
  {
    path: "/restful-client-using-cxf",
    component: RestfulClientUsingCXF
  },
  {
    path: "/jaxws-soap-webservice-using-cxf",
    component: SOAPWebserviceUsingCXF
  },
  {
    path: "/jaxws-soap-client-using-cxf",
    component: SOAPClientUsingCXF
  },
  {
    path: "/restful-webservice-using-jersey",
    component: RestfulWebserviceUsingJersey
  },
  {
    path: "/restful-client-using-jersey",
    component: RestfulClientUsingJersey
  },
  {
    path: "/react-router-apache-nginx-tomcat",
    component: ReactRoutingConfig
  },
  {
    path: "/create-react-app-react-router",
    component: CRAReactRoutingConfig
  },
  {
    path: "/react-widgetpack-free-disqus-alternative/",
    component: ReactWidgetPackDisqusComments
  },
  {
    path: "/angular-router-config-apache-nginx-tomcat",
    component: AngularRoutingConfig
  },
  {
    path: "/angular-router-config-cli-generate-app",
    component: AngularRoutingConfigCLIApp
  },
  {
    path: "/react-proxying-api-backend-apache-nginx-tomcat",
    component: ReactProxyConfig
  },
  {
    path: "/how-to-configure-react-and-redux/",
    component: ReactReduxConfig
  },
  {
    path: "/3-ways-to-convert-soapmessage-to-java-object-with-namespace/",
    component: SOAPMessageToObject
  },
  {
    path: "/how-to-create-react-table-with-pagination-and-sorting/",
    component: ReactTableWithPagination
  },
  {
    path: "/react-loading-spinner-with-overlay/",
    component: ReactLoadingSpinnerWithOverlay
  },
  {
    path: "/react-table-with-loading-spinner/",
    component: ReactTableWithLoadingSpinner
  },
  {
    path: "/spring-webclient-get-post-put-delete-example/",
    component: WebClientCRUD
  },
  {
    path: "/4-ways-to-test-webclient-mocking/",
    component: WebClientTesting
  },
  {
    path: "/react-table-with-record-count/",
    component: ReactTableWithRecordCount
  },
  {
    path: "/react-table-with-nested-subtable/",
    component: ReactTableWithNestedSubTable
  },
  {
    path: "/easy-guide-to-ssl/",
    component: EasyGuideToSSL
  },
  {
    path: "/convert-p12-to-jks-and-pem-to-jks/",
    component: PKCSAndPEMToJKS
  },
  {
    path: "/tls-mutual-auth-two-way-ssl-postman-soapui/",
    component: TLSMutualAuth
  },
  //Do not move the search above to avoid this to take over other routes
  {
    path: "/:criteria",
    component: Search
  }
];

export default Routes;
