import React from "react";
import PropTypes from "prop-types";
import RenderIcon from "components/CustomWidgets/RenderIcon";
import {
  facebook,
  facebookSquare,
  twitter,
  twitterSquare,
  linkedin,
  linkedinSquare
} from "react-icons-kit/fa";

const SocialMediaIcons = props => (
  <ul className="social-network">
    <li>
      <a target="blank" href="https://www.facebook.com/learninjava">
        <RenderIcon
          name={props.type == "square" ? facebookSquare : facebook}
          type="icon"
          size={props.size}
          color={props.color == "themed" ? "#3b5998" : props.color}
        />
      </a>
    </li>
    <li>
      <a target="blank" href="https://twitter.com/learninjava">
        <RenderIcon
          name={props.type == "square" ? twitterSquare : twitter}
          type="icon"
          size={props.size}
          color={props.color == "themed" ? "#00aced" : props.color}
        />
      </a>
    </li>
    <li>
      <a target="blank" href="https://www.linkedin.com/company/learninjava/">
        <RenderIcon
          name={props.type == "square" ? linkedinSquare : linkedin}
          type="icon"
          size={props.size}
          color={props.color == "themed" ? "#0e76a8" : props.color}
        />
      </a>
    </li>
  </ul>
);

SocialMediaIcons.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.number
};

SocialMediaIcons.defaultProps = {
  color: "themed",
  type: "none",
  size: 24
};

export default SocialMediaIcons;
