import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["reactTableWithLoadingSpinner"],
  ...allHashTags,
  channel: "23"
};

const data = [
  {
    type: "toc",
    title: "React Table Series - Table of Contents",
    content:
      "<div class='lineBreak' /> " +
      "<h4><b>Part 1 : </b> <MyLink class='homelink' href='/how-to-create-react-table-with-pagination-and-sorting' target='_blank'>" +
      "React Table - How to create a simple react table with pagination and sorting</MyLink></h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 2 : </b> React Table - How to add Loading Spinner - [This article]</h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 3 : </b> <MyLink class='homelink' href='/react-table-with-record-count' target='_blank'>" +
      "React Table - How to add a Record Count</MyLink></h4>" +
      "<h4><b>Part 4 : </b> <MyLink class='homelink' href='/react-table-with-nested-subtable' target='_blank'>" +
      "React Table - How to create a nested sub table</MyLink></h4>"
  },
  {
    type: "subheading",
    name: "react-table-with-pagination",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "In our previous article, we learned how to create a simple table in React. " +
      "In this continuation article, we will add a loading spinner to the table we created here, " +
      "<MyLink class='homelink' href='/how-to-create-react-table-with-pagination-and-sorting' target='_blank'>" +
      "How to create a simple react table with pagination and sorting</MyLink>" +
      "<div class='lineBreakSmall' /> " +
      "Go to the <code>Download Source</code> section of the above tutorial and once you get access to the source, follow the below steps, " +
      "as we will be modifying some of the files in that app."
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "install-font-awesome",
    title: "Install font-awesome"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Open command prompt, navigate to the application root folder and type," +
      "<div class='lineBreak' /> " +
      "<code>npm install font-awesome</code>" +
      "<div class='lineBreakSmall' /> " +
      "We will use the spinner icon from this library, you can use any other if you want."
  },
  {
    type: "subheading",
    name: "install-classnames",
    title: "Install classnames"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Type below command while in command promt," +
      "<div class='lineBreak' /> " +
      "<code>npm install classnames</code>" +
      "<div class='lineBreak' /> " +
      "This library allows us to conditionally join and manipulate CSS classNames."
  },
  {
    type: "subheading",
    name: "create-loading-spinner",
    title: "Create LoadingSpinner component"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Create a new file named <code>LoadingSpinner.js</code> and add the code as below."
  },
  {
    type: "gist",
    content: "60e66275be6bc18ae3f732c260d2323a"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Let's see the importants aspects of the above code snippet:" +
      "<div class='lineBreakSmall' /> " +
      "1. Imported <code>font-awesome</code> and used its <code>fa-spinner</code> icon" +
      "<div class='lineBreakSmall' /> " +
      "2. Used <code>classnames</code> library to make it active conditionally using props.loading property"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "add-loading-spinner-to-react-table",
    title: "Add LoadingSpinner component to React Table"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Now, open Dashboard.js and modify the code as below. This component " +
      "just fetches the data and displays as a table."
  },
  {
    type: "gist",
    content: "acc2f5e8ab6c6ec1324e4b19ad2793e5"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The above code is exactly same as the original code in the previous article, " +
      "except for the numbered lines. " +
      "Let's see what are the differences at the highlighted line numbers:" +
      "<div class='lineBreakSmall' /> " +
      "1. First we imported the LoadingSpinner component" +
      "<div class='lineBreakSmall' /> " +
      "2. Default table properties <code>ReactTableDefaults</code> are updated to include the LoadingSpinner" +
      "<div class='lineBreakSmall' /> " +
      "3. Modified the default value of <code>loading</code> state property value to true. This is to make the spinner visible when the page first loads the table" +
      "<div class='lineBreakSmall' /> " +
      "4. Finally, setting the <code>loading</code> state property value to false once the data has been fetched from the API"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Now, Open the browser and test the code, on page load, you should see a loading indicator being shown " +
      "before the data had been fetched. Once the data is retrieved, the loading indicator is hidden. Also, try to navigate using the pagination " +
      "buttons at the bottom of the table to see the loading spinner in action."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-table-with-loading-spinner.jpg"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/how-to-create-react-table-with-pagination-and-sorting/",
    previousText:
      "React Table - How to create a simple react table with pagination and sorting",
    nextURL: "/react-table-with-record-count/",
    nextText: "React Table - How to add a Record Count"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/react-table-loading-spinner.zip"
  }
];

class ReactTableWithLoadingSpinner extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default ReactTableWithLoadingSpinner;
