import React from "react";
import PropTypes from "prop-types";
import GoogleAdContainer from "components/Ads/GoogleAdContainer";
import "./DownloadModal.css"; // Import the CSS file for styling

const DownloadModal = ({ show, url, handleClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Download</h4>
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          <p>
            <GoogleAdContainer position="popup" />
          </p>
          <a href={url} download>
            Download Source
          </a>
        </div>
        <div className="modal-footer">
          <button className="close-button" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

DownloadModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired
};

export default DownloadModal;
