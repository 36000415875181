import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ErrorBoundary from "components/CustomWidgets/ErrorBoundary";

const CommentContainer = ({ enable }) => {
  const giscusContainerRef = useRef(null);
  useEffect(() => {
    if (enable && giscusContainerRef.current) {
      const script = document.createElement("script");
      script.src = "https://giscus.app/client.js";
      script.setAttribute("data-repo", "learninjavagithub/discussions");
      script.setAttribute("data-repo-id", "R_kgDONsqR5g");
      script.setAttribute("data-category", "Announcements");
      script.setAttribute("data-category-id", "DIC_kwDONsqR5s4CmKwB");
      script.setAttribute("data-mapping", "og:title");
      script.setAttribute("data-strict", "0");
      script.setAttribute("data-reactions-enabled", "1");
      script.setAttribute("data-emit-metadata", "0");
      script.setAttribute("data-input-position", "top");
      script.setAttribute("data-theme", "preferred_color_scheme");
      script.setAttribute("data-lang", "en");
      script.setAttribute("data-loading", "lazy");
      script.setAttribute("crossorigin", "anonymous");
      script.async = true;

      giscusContainerRef.current.appendChild(script);

      return () => {
        if (giscusContainerRef.current) {
          giscusContainerRef.current.removeChild(script);
        }
      };
    }
  }, [enable]);

  return (
    <React.Fragment>
      <ErrorBoundary>
        {enable ? (
          <div ref={giscusContainerRef}>
            <div id="giscus"></div>
          </div>
        ) : (
          ""
        )}
      </ErrorBoundary>
    </React.Fragment>
  );
};

CommentContainer.propTypes = {
  enable: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired
};

export default CommentContainer;
