import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import MenuItem from "components/Menu/MenuItem";
import RenderIcon from "components/CustomWidgets/RenderIcon";
import SpinningLogo from "components/CustomWidgets/SpinningLogo";

import { home, cogs, leaf, phoneSquare } from "react-icons-kit/fa";
import { faJava, faReact, faAngular } from "@fortawesome/free-brands-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };
  }

  handleChange = event => {
    this.setState({
      search: event.target.value
    });
  };

  handleSearch = () => {
    if (this.state.search) {
      this.props.history.push("/" + this.state.search + "/");
    }
    this.setState({
      search: ""
    });
  };

  render() {
    return (
      <header>
        <div className="navbar navbar-default navbar-fixed-top">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="/">
                <SpinningLogo />
                &nbsp;Learn in&nbsp;
                <span>java</span>
              </a>
            </div>
            <div className="navbar-collapse collapse ">
              <ul className="nav navbar-nav">
                <MenuItem
                  icon={home}
                  link="/"
                  color="#ef6c00"
                  text="Home"
                  isSubMenu={false}
                />
                {/*
                <li className="nav-item dropdown">
                  <a
                    href="#"
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="navbarDropdownMenuLink"
                  >
                    <i
                      className="fab fa-java fa-3x"
                      style={{ fontSize: "18px", color: "#086eb6" }}
                    ></i>
                    &nbsp;&nbsp;<span className="menu-text">Java </span>
                    <b className="fa fa-angle-down"></b>
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li className="dropdown-submenu">
                      <a
                        className="dropdown-item dropdown-toggle"
                        style={{ fontSize: "14px" }}
                        href="#"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        New Features in
                      </a>
                      <ul className="dropdown-menu">
                        <MenuItem
                          link="/java-programming/"
                          text="Java 4"
                          isSubMenu={true}
                        />
                        <MenuItem
                          link="/java-programming/"
                          text="Java 5"
                          isSubMenu={true}
                        />
                        <MenuItem
                          link="/java-programming/"
                          text="Java 6"
                          isSubMenu={true}
                        />
                        <MenuItem
                          link="/java-programming/"
                          text="Java 7"
                          isSubMenu={true}
                        />
                        <MenuItem
                          link="/java8/"
                          text="Java 8"
                          isSubMenu={true}
                        />
                        <MenuItem
                          link="/java-programming/"
                          text="Java 9"
                          isSubMenu={true}
                        />
                        <MenuItem
                          link="/java-programming/"
                          text="Java 10"
                          isSubMenu={true}
                        />
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="/java-programming/">Collections</a>
                    </li>
                    <li className="nav-item">
                      <a href="/java-programming/">Threads</a>
                    </li>
                  </ul>
                </li>*/}
                <MenuItem
                  icon={faJava}
                  link="/java-programming/"
                  iconType="faIcon"
                  color="#086eb6"
                  text="Java"
                  isSubMenu={false}
                />
                <MenuItem
                  icon={cogs}
                  link="/webservices/"
                  color="#416284"
                  text="Web Services"
                  isSubMenu={false}
                />
                <MenuItem
                  icon={leaf}
                  link="/spring/"
                  color="#68bd45"
                  text="Spring"
                  isSubMenu={false}
                />
                <MenuItem
                  icon={faReact}
                  iconType="faIcon"
                  link="/react/"
                  color="#00d8fb"
                  text="React JS"
                  isSubMenu={false}
                />
                <MenuItem
                  icon={faAngular}
                  iconType="faIcon"
                  link="/angular/"
                  color="#e42839"
                  text="Angular"
                  isSubMenu={false}
                />
                <MenuItem
                  icon={phoneSquare}
                  link="/contact/"
                  color="#459fdd"
                  text="Contact"
                  isSubMenu={false}
                />
                <li>
                  <div
                    className="input-group"
                    style={{
                      width: "200px",
                      marginTop: "10px",
                      marginLeft: "10px"
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={this.state.search}
                      onChange={this.handleChange}
                      onKeyPress={e => {
                        if (e.key === "Enter") {
                          this.handleSearch();
                        }
                      }}
                    />
                    <span
                      className="input-group-addon CursorPointer"
                      onClick={this.handleSearch}
                    >
                      <RenderIcon name={faSearch} type="faIcon" size={20} />
                    </span>
                  </div>
                </li>
                <li>
                  <span style={{ marginRight: "10px" }}>&nbsp;</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  history: PropTypes.object
};

export default withRouter(Header);
