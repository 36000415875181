import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["threadStates"],
  ...allHashTags,
  channel: "4"
};

const data = [
  {
    type: "subheading",
    name: "thread-states",
    title: "Thread States"
  },
  {
    type: "text",
    content:
      "A Java thread can be in any of the following states :" +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "<li>New</li>" +
          "<li>Runnable</li>" +
          "<li>Running</li>" +
          "<li>Sleeping/Waiting/Blocked</li>" +
          "<li>Stopped/Dead</li>"
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Now, to understand these states in a better way, look at the below screenshot from a famous game, yes, Angry Birds :) Ofcourse, i modified the screenshot to make you understand the thread states in a simple and easy way."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/thread-states.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Let us see each of the thread states in detail :" +
      "<div class='lineBreakSmall' /> " +
      "<b>New</b> - The thread object has been created but the thread is not considered alive as yet. Compare this to the birds in 'New' box, they are created but not in action i.e, alive in case of a thread." +
      "<div class='lineBreakSmall' /> " +
      "<b>Runnable</b> - In this state, the thread is considered alive and ready to run. Compare this to the bird in the 'Runnable' box, the bird on the Y shaped stick is ready to hit the pig." +
      "<div class='lineBreakSmall' /> " +
      "<b>Running</b> - The thread's run() method is called. The thread is alive and running. Compare this to the bird in the 'Running' box, the bird is in action." +
      "<div class='lineBreakSmall' /> " +
      "<b>Waiting/Sleeping/Blocked for IO</b> - This state is where we see the threads awaiting for some response. A thread can be in waiting state when it requires a lock but is currently being used by some other thread. A thread can be in sleeping state when its sleep() method is called. A thread can be in blocked state when it is waiting for some IO operation to complete like reading/writing a file etc." +
      "<div class='lineBreakSmall' /> " +
      "Compare this to the birds in the powerups bar. In the game, we have a few birds waiting in the powerup bar which can be made runnable at any moment. One difference in multithreading however is that the threads in these states becomes runnable only after they get what they wanted." +
      "<div class='lineBreakSmall' /> " +
      "<b>Stopped/Dead</b> - In this state, the thread is considered dead and cannot be run. See the dead bird there ?"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "<b>Rules to remember:</b>" +
          "<div class='lineBreakSmall' /> " +
          "1. Starting a thread that is already running causes IllegalThreadStateException" +
          "<div class='lineBreakSmall' /> " +
          "2. Calling the run() method directly instead of start() method will not create a new thread instead only the method will be executed"
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Let us see the lifecycle of a thread. See the below screenshot. Notice the green arrows ? The green arrows shows the directions in which a thread state can move to/from another state." +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/thread-states-lifecycle.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "As you can see a 'New' thread can only become 'Runnable' and a 'Runnable' thread can only become 'Running'. A 'Running' thread can become a Wating/Sleeping/Blocked thread. This is an exception to the angry birds rules. We know that a bird once running cannot move to powerup bar. Also, a 'Running' thread can become 'Runnable' and 'Stopped/Dead'. Finally, remember that any thread in the waiting/sleeping/blocked state can become a 'Runnable' thread only. They cannot be made 'Running' directly." +
      "<div class='lineBreak' /> " +
      "For the exact method call in each of the above cases, refer to the table below:"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<table border='1px' style='padding: 2px; border-spacing: 2px'><tr>" +
      "<td><b>From State(s)</b></td><td><b>To State(s)</b></td><td><b>Method Name(s)</b></td>" +
      "<tr><td>New</td><td>Runnable</td><td></td></tr>" +
      "<tr><td>Runnable</td><td>Running</td><td><code>run()</code></td></tr>" +
      "<tr><td>Running</td><td>Waiting</td><td><code>wait()/join()</code></td></tr>" +
      "<tr><td>Running</td><td>Sleeping</td><td><code>sleep()</code></td></tr>" +
      "<tr><td>Running</td><td>Blocked</td><td></td></tr>" +
      "<tr><td>Running</td><td>Runnable</td><td><code>yeild()</code></td></tr>" +
      "<tr><td>Waiting</td><td>Runnable</td><td><code>notify()/notifyAll()</code></td></tr>" +
      "<tr><td>Sleeping</td><td>Runnable</td><td></td></tr>" +
      "<tr><td>Blocked</td><td>Runnable</td><td></td></tr>" +
      "<tr><td>Running</td><td>Stopped/Dead</td><td></td></tr>" +
      "</tr></table>"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "<b>How to remember:</b>" +
          "<div class='lineBreakSmall' /> " +
          "Simple! Remember the above two diagrams ;)"
      }
    ]
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/why-equals-and-hashcode-override/",
    previousText: "Equals and HashCode Override",
    nextURL: "/user-thread-and-daemon-thread/",
    nextText: "User and Daemon Threads"
  }
];

class ThreadStates extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default ThreadStates;
