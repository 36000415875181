import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["functionalInterfaces"],
  ...allHashTags,
  channel: "1"
};

const data = [
  {
    type: "subheading",
    name: "java-functional-interfaces",
    title: "Java 8 - Functional Interfaces"
  },
  {
    type: "text",
    content:
      "A functional interface is any interface with only one method in it."
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "Java API classes already uses functional interfaces, examples includes :<br /> " +
          "1. <code>java.lang.Runnable</code> with only a run() method<br/> " +
          "2. <code>java.util.Comparator</code> with only a compare() method "
      }
    ]
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Note that the interface can include methods from object class like <code>equals(), hashCode()</code> etc which are not considered " +
      "as methods of the interface. In order for an interface to be called a functional interface, it must have " +
      "defined methods of its own."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Java 8 has introduced a new annotation called <code>@FunctionalInterface</code>. " +
      "This annotation is used to check for compile time type safety on functional interfaces. " +
      "See the below example :"
  },
  {
    type: "gist",
    content: "670bc113ec3c59bdcfae905bf46a9294"
  },
  {
    type: "output",
    content: "\rHit the pig using Red Angry Bird...\r\n"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "In this example, there is a functional interface named AngryBird. " +
      "This has only one method <code>hitThePig()</code>. " +
      "And<code>RedAngryBird</code> is a class that implements this interface " +
      "and provides the implementation." +
      "<div class='lineBreak' /> " +
      "In the <code>main()</code> method, we tried calling the functional interface method using outer class instance. " +
      "Dont get confused by the syntax, we are just calling the inner class(<code>RegAngryBird</code>) using the Outer class instance, " +
      "functionalInterfaceExample1." +
      "<div class='lineBreak' /> " +
      "Now, where did we use the functional interface annotation. Well, the functional interface is already did its task, compile time type safety. " +
      "It already checked to see if there is only one method in the interface or not. Since we had only one method all went well. " +
      "<div class='lineBreak' /> " +
      "Let us try adding one more method to this interface and see what happens... " +
      "Try adding the below method to the AngryBird interface. " +
      "<div class='lineBreak' /> " +
      "<code>public void hitThePigAgain();</code>" +
      "<div class='lineBreak' /> " +
      "You will get the below compiler error:"
  },
  {
    type: "output",
    content:
      "\rInvalid '@FunctionalInterface' annotation; FunctionalInterfaceExample1.AngryBird is not a functional interface\r\n"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /> "
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "A functional interface is an interface which can have one and only one method inside it. "
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Ok, now what if we have an interface which has more than one method in it but still lambda expressions could be used. " +
      "Since we do not know what lambda expressions are yet, consider that we want to use only one method from a functional " +
      "interface which has multiple methods. How to acheive this ? " +
      "<div class='lineBreakSmall' /> " +
      "The same challenge was faced by java api creators while extending collections api to support lambda expressions. To facilitate " +
      "this, Java 8 has introduced <b>default methods/defender methods/virtual extension methods</b>. " +
      "Consider the below example, "
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "gist",
    content: "23f3105a1c5ad124e9632ff9617e9768"
  },
  {
    type: "output",
    content:
      "\rHit the pig using Red Angry Bird..." +
      "\rHit the pig using default Angry Bird...\r\n"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "From the above example, we can see that there is another method added to AngryBird called <code>hitThePigAgain()</code>. " +
      "Observe the default keyword before the method declaration."
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "Dont get confused between default access modifier and default keyword. " +
          "Remember that default access modifier does not have any keyword. For example, " +
          "the below method's access modifier is default and it is also a default/defender method. "
      },
      {
        type: "gist",
        content: "540916ea4485362e1bfae079f73074de"
      }
    ]
  },
  {
    type: "googleAd",
    position: "middle4"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "We will see a realtime usage of default method in Collections API while using <MyLink class='homelink' href='/java8-stream' target='_blank'><span>Stream</span></MyLink>." +
      "<div class='lineBreak' />" +
      "For now, just remember that default method allows us to use the interface as a functional interface by providing default implementation. " +
      "<div class='lineBreak' />" +
      "Note that, by using default methods, we are actually adding implemenation to interface which are supposed " +
      "to contain only abstract methods. Yes, from Java 8 onwards, interfaces can contain implementation via default methods. "
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/java8-stream/",
    previousText: "Java 8 - Stream",
    nextURL: "/java8-lambda-expressions/",
    nextText: "Java 8 - Lambda Expressions"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/java8-functional-interfaces.zip"
  }
];

const FunctionalInterfaces = () => {
  return (
    <>
      <Article header={header} data={data} />
    </>
  );
};

export default FunctionalInterfaces;
