import React from "react";
import PropTypes from "prop-types";

import MultiContentRenderer from "components/common/MultiContentRenderer";

import { lightbulbO } from "react-icons-kit/fa/lightbulbO";
import Icon from "react-icons-kit";

class InfoBox extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, content } = this.props;
    return (
      <div className="alertCard">
        <div className="alertHeader">
          <span style={{ color: "#f7ca3d" }}>
            <Icon icon={lightbulbO} size={24} />
          </span>
          &nbsp;{title}
        </div>
        <div className="alertBody">
          {content.map((section, i) => (
            <MultiContentRenderer key={i} section={section} />
          ))}
        </div>
      </div>
    );
  }
}

InfoBox.defaultProps = {
  title: "Remember!"
};

InfoBox.propTypes = {
  title: PropTypes.string,
  content: PropTypes.array
};

export default InfoBox;
