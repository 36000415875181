import allHeaders from "components/Properties/AllHeaders";

const springURLs = [];

const angularURLs = [
  {
    id: 16,
    ...allHeaders["angularRouterApacheNginxTomcat"],
    url: "/angular-router-config-apache-nginx-tomcat/",
    desc:
      "This Angular post explains how to configure angular router on Apache HTTP Server, " +
      "Nginx Server and Tomcat Server. The angular post explains client-side and server-side " +
      "routing and production ready config on three most famous servers: Apache HTTP Server, Nginx and Tomcat." +
      "Difference between HashLocationStrategy and PathLocationStrategy explained as well. Also has working" +
      "example and downloadable source code.",
    icon: "fab-angular",
    iconType: "faIcon",
    iconColor: "#e42839"
  },
  {
    id: 17,
    ...allHeaders["angularRouterConfigCLIApp"],
    url: "/angular-router-config-cli-generate-app/",
    desc:
      "This Angular tutorial explains how to configure angular router on Angular CLI, " +
      "generate application. The tutorial explains how to import @angular/router module and " +
      "configure the ng new basic application step by step.",
    icon: "fab-angular",
    iconType: "faIcon",
    iconColor: "#e42839"
  }
];

const reactURLs = [
  {
    id: 13,
    ...allHeaders["reactRouterApacheNginxTomcat"],
    url: "/react-router-apache-nginx-tomcat/",
    desc:
      "This React post explains how to configure react router on Apache HTTP Server, " +
      "Nginx Server and Tomcat Server. The react post explains client-side and server-side " +
      "routing and production ready config on three most famous servers: Apache HTTP Server, Nginx and Tomcat." +
      "Difference between BrowserRouter and HashRouter explained as well. Also has working" +
      "example and downloadable source code.",
    icon: "fab-react",
    iconType: "faIcon",
    iconColor: "#00d8fb"
  },
  {
    id: 14,
    ...allHeaders["createReactAppReactRouter"],
    url: "/create-react-app-react-router/",
    desc:
      "This React post explains how to configure react router on create-react-app (CRA) " +
      "step by step with a working example and downloadable source code included." +
      "The working react sample application is easy to understand.",
    icon: "fab-react",
    iconType: "faIcon",
    iconColor: "#00d8fb"
  },
  {
    id: 15,
    ...allHeaders["reactWidgetPackDisqus"],
    url: "/react-widgetpack-free-disqus-alternative/",
    desc:
      "This react tutorial explains how to use widgetpack's comments widget as a free alternative to " +
      "disqus commenting system using React. The example application is provided for easy understanding. " +
      "It uses react-widgetpack-comments npm module. A full Commenting system can be implemented on your " +
      "website with just 1 or 2 lines of code.",
    icon: "fab-react",
    iconType: "faIcon",
    iconColor: "#00d8fb"
  },
  {
    id: 18,
    ...allHeaders["reactProxyApacheNginxTomcat"],
    url: "/react-proxying-api-backend-apache-nginx-tomcat/",
    desc:
      "This React post explains how to proxy backend API requests in react on Apache HTTP Server, " +
      "Nginx Server and Tomcat Server. The react post explains how to setup API proxy configuration " +
      "on create-react-app(CRA) application step by step. The proxy configuration is production ready and working.",
    icon: "fab-react",
    iconType: "faIcon",
    iconColor: "#00d8fb"
  },
  {
    id: 19,
    ...allHeaders["reactReduxConfig"],
    url: "/how-to-configure-react-and-redux/",
    desc:
      "This React post explains how to configure react and redux in a simple and easy to understand " +
      "approach. Explains what are Actions, Action Types, Reducers ? and what is Thunk middleware ? " +
      "The post includes working example which is production ready configuration.",
    icon: "fab-react",
    iconType: "faIcon",
    iconColor: "#00d8fb"
  },
  {
    id: 21,
    ...allHeaders["reactTableWithPagination"],
    url: "/how-to-create-react-table-with-pagination-and-sorting/",
    title:
      "React Table - How to create a simple react table with pagination and sorting",
    desc:
      "This tutorial explains with example, how to create a simple react table with pagination and sorting in ascending and " +
      "descending order in quick and easy step by step approach.",
    icon: "fab-react",
    iconType: "faIcon",
    iconColor: "#00d8fb"
  },
  {
    id: 22,
    ...allHeaders["reactLoadingSpinnerWithOverlay"],
    url: "/react-loading-spinner-with-overlay/",
    title: "Guide to create React Loading Spinner with Overlay",
    desc:
      "This tutorial explains with examples, how to create a simple loading spinner using react " +
      "and font-awesome components with and without using an overlay created with react-loading-overlay component.",
    icon: "fab-react",
    iconType: "faIcon",
    iconColor: "#00d8fb"
  },
  {
    id: 23,
    ...allHeaders["reactTableWithLoadingSpinner"],
    url: "/react-table-with-loading-spinner/",
    title: "React Table - How to add a loading spinner",
    desc:
      "This tutorial explains with working code, how to add a loading spinner to " +
      "react table.",
    icon: "fab-react",
    iconType: "faIcon",
    iconColor: "#00d8fb"
  },
  {
    id: 26,
    ...allHeaders["reactTableWithRecordCount"],
    url: "/react-table-with-record-count/",
    title: "React Table - How to add a Record Count",
    desc:
      "This tutorial explains with working code, how to add a record count to " +
      "react table.",
    icon: "fab-react",
    iconType: "faIcon",
    iconColor: "#00d8fb"
  },
  {
    id: 27,
    ...allHeaders["reactTableWithNestedSubTable"],
    url: "/react-table-with-nested-subtable/",
    title: "React Table - How to create a nested sub table",
    desc:
      "This tutorial explains with working code, how to create a nested child sub table " +
      "inside a react table.",
    icon: "fab-react",
    iconType: "faIcon",
    iconColor: "#00d8fb"
  }
];

const webservicesURLs = [
  {
    id: 6,
    ...allHeaders["restfulWebserviceUsingCXF"],
    url: "/restful-webservice-using-cxf/",
    desc:
      "This tutorial explains how to create a JAX-RS restful " +
      "webservice using Spring and CXF.For your convenience, we " +
      "have provided configurations for running the webservice on both " +
      "Jetty and Tomcat servers.",
    icon: "fas-cogs",
    iconType: "faIcon",
    iconColor: "#416284"
  },
  {
    id: 8,
    ...allHeaders["restfulClientUsingCXF"],
    url: "/restful-client-using-cxf/",
    desc:
      "This tutorial explains how to create a JAX-RS restful " +
      "client using Spring and CXF. In our JAX-RS webservice using CXF " +
      "tutorial, we have created a restful webservice that contains two methods. " +
      "One method accepts text type input while the second one accepts a JsonBean type",
    icon: "fas-cogs",
    iconType: "faIcon",
    iconColor: "#416284"
  },
  {
    id: 9,
    ...allHeaders["jaxwsWebserviceUsingCXF"],
    url: "/jaxws-soap-webservice-using-cxf/",
    desc:
      "This tutorial explains how to create a JAX-WS SOAP based " +
      "webservice using Spring and CXF. For your convenience, we " +
      "have provided configurations for running the webservice on both Jetty and Tomcat servers.",
    icon: "fas-cogs",
    iconType: "faIcon",
    iconColor: "#416284"
  },
  {
    id: 10,
    ...allHeaders["jaxwsClientUsingCXF"],
    url: "/jaxws-soap-client-using-cxf/",
    desc:
      "This tutorial explains how to create a JAX-WS SOAP based " +
      "client using Spring and CXF. There are number of ways you can test this SOAP webservice.",
    icon: "fas-cogs",
    iconType: "faIcon",
    iconColor: "#416284"
  },
  {
    id: 11,
    ...allHeaders["restfulWebserviceUsingJersey"],
    url: "/restful-webservice-using-jersey/",
    desc:
      "This tutorial explains how to create a Restful " +
      "webservice using Spring and Jersey. For your convenience, " +
      "we have provided configurations for running the webservice on " +
      "both Jetty and Tomcat servers.",
    icon: "fas-cogs",
    iconType: "faIcon",
    iconColor: "#416284"
  },
  {
    id: 12,
    ...allHeaders["restfulClientUsingJersey"],
    url: "/restful-client-using-jersey/",
    desc:
      "This tutorial explains how to create a Restful " +
      "client using Spring and Jersey. We will try to create a client and " +
      "call the ping() method using Pure JAX-RS API without using Jersey and " +
      "also using Jersey API.",
    icon: "fas-cogs",
    iconType: "faIcon",
    iconColor: "#416284"
  },
  {
    id: 20,
    ...allHeaders["soapMessageToObject"],
    url: "/3-ways-to-convert-soapmessage-to-java-object-with-namespace/",
    title:
      "3 ways to convert SOAPMessage to Java Object with namespace and " +
      "XML to SOAPMessage",
    desc:
      "This article provides one stop guide to convert SOAPMessage to Java Object with namespaces, " +
      "SOAPMessage as XML to SOAPMessage Object using Jaxb2Marshaller, " +
      "XMLStreamReader, JAXBContext and Document APIs",
    icon: "fas-cogs",
    iconType: "faIcon",
    iconColor: "#416284"
  },
  {
    id: 24,
    ...allHeaders["webClientCRUD"],
    url: "/spring-webclient-get-post-put-delete-example/",
    title:
      "How to create Spring WebClient CRUD - GET, POST, PUT and DELETE Examples",
    desc:
      "Spring WebClient - GET, PUT, POST, DELETE examples: Learn how to create, read, " +
      "update and delete using WebClient, an alternative to RestTemplate, examples " +
      "using GET, POST, PUT and DELETE http methods.",
    icon: "fas-cogs",
    iconType: "faIcon",
    iconColor: "#416284"
  },
  {
    id: 25,
    ...allHeaders["webClientTesting"],
    url: "/4-ways-to-test-webclient-mocking/",
    title:
      "4 Ways to test WebClient using Mockito, MockWebServer, WebTestClient and WireMockServer",
    desc:
      "Mocking a WebClient in Spring: Learn how to test WebClient, examples include " +
      "testing using Mockito, MockWebServer, WebTestClient and WireMockServer.",
    icon: "fas-cogs",
    iconType: "faIcon",
    iconColor: "#416284"
  }
];

const javaURLs = [
  {
    id: 3, // used by minisearch very imp order by sitemap.xml order
    ...allHeaders["stream"],
    url: "/java8-stream/",
    desc:
      "Java 8 has introduced a new concept called Stream. These are a " +
      "group of computations specified as a sequence of steps. Although, " +
      "the name sounds similar to Java IO API classes like InputStream/OutputStream, " +
      "this has nothing to do with them",
    icon: "fab-java",
    iconType: "faIcon",
    iconColor: "#086eb6"
  },
  {
    id: 1,
    ...allHeaders["functionalInterfaces"],
    url: "/java8-functional-interfaces/",
    title: "Java 8 Functional Interfaces explained",
    desc:
      "A functional interface is any interface with only one method in it. Java 8 " +
      "has introduced a new annotation called @FunctionalInterface. This annotation is " +
      "used to check for compile time type safety on functional interfaces",
    icon: "fab-java",
    iconType: "faIcon",
    iconColor: "#086eb6"
  },
  {
    id: 2,
    ...allHeaders["lambdaExpressions"],
    url: "/java8-lambda-expressions/",
    title: "Java 8 Lambda Expressions explained",
    desc:
      "Java 8 Lambda Expressions treats functionality as method arguments or code as data. " +
      "Very difficult definition to understand ? For Me too!! Simply said, lambda expressions " +
      "simplifies the syntax when we have only one method in an interface which needs to be called",
    icon: "fab-java",
    iconType: "faIcon",
    iconColor: "#086eb6"
  },
  {
    id: 7,
    ...allHeaders["equalsAndHashCodeOverride"],
    url: "/why-equals-and-hashcode-override/",
    title: "Equals and HashCode Override",
    desc:
      "The reason why you should override hashcode whenever you override equals is that " +
      "you need a way to create the same object you want to find. This is particularly " +
      "useful when you are dealing with collections.",
    icon: "fab-java",
    iconType: "faIcon",
    iconColor: "#086eb6"
  },
  {
    id: 4,
    ...allHeaders["threadStates"],
    url: "/thread-states/",
    title: "Thread States",
    desc:
      "Java Thread States explained in a simple and easy to understand approach. Blocked state " +
      "is where we see the threads awaiting for some response. A thread can be in waiting " +
      "state when it requires a lock but is currently being used by some other thread",
    icon: "fab-java",
    iconType: "faIcon",
    iconColor: "#086eb6"
  },
  {
    id: 5,
    ...allHeaders["userThreadDaemonThread"],
    url: "/user-thread-and-daemon-thread/",
    title: "User Thread and Daemon Threads",
    desc:
      "A User thread is a thread that is created by the User i.e, the application where " +
      "as a Daemon thead is a thread that is created to serve the user thread. A Daemon " +
      "thread is usually made to run in the background continuously",
    icon: "fab-java",
    iconType: "faIcon",
    iconColor: "#086eb6"
  },
  {
    id: 28,
    ...allHeaders["easyGuideToSSL"],
    url: "/easy-guide-to-ssl/",
    title: "Easy Guide to SSL - All the terms you need to know",
    desc:
      "A simple and easy guide to SSL concepts and all the terminology involved in SSL." +
      "The most confusing terms in SSL are explained in an easy to understand way.",
    icon: "fab-java",
    iconType: "faIcon",
    iconColor: "#086eb6"
  },
  {
    id: 29,
    ...allHeaders["pkcsAndPemToJks"],
    url: "/convert-p12-to-jks-and-pem-to-jks/",
    title: "Convert PKCS#12 to JKS and PEM to JKS format",
    desc:
      "A simple and easy guide to convert PKCS#12(Public Key Cryptography Standards) " +
      "to JKS(Java Key Store) and PEM(Privacy Enhanced Mail) to JKS(Java Key Store) format.",
    icon: "fab-java",
    iconType: "faIcon",
    iconColor: "#086eb6"
  },
  {
    id: 30,
    ...allHeaders["tlsMutualAuth"],
    url: "/tls-mutual-auth-two-way-ssl-postman-soapui/",
    title:
      "Two way SSL/Mutual Authentication - How to use client certificate in Postman and SOAP UI",
    desc:
      "Demonstrates how to configure and use TLS Mutual Authentication/Two way SSL Authentication/" +
      "Client Certificates in POSTMAN and SOAP UI.",
    icon: "fab-java",
    iconType: "faIcon",
    iconColor: "#086eb6"
  }
];

export const allURLs = [
  ...javaURLs,
  ...springURLs,
  ...webservicesURLs,
  ...reactURLs,
  ...angularURLs
];

export const allCategories = {
  java8: javaURLs,
  java: javaURLs,
  spring: springURLs,
  webservices: webservicesURLs,
  reactjs: reactURLs,
  angular: angularURLs
};

export const anyCategories = {
  any: allURLs
};
