import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["webClientCRUD"],
  ...allHashTags,
  channel: "24"
};

const data = [
  {
    type: "toc",
    title: "WebClient Series - Table of Contents",
    content:
      "<div class='lineBreak' /> " +
      "<h4><b>Part 1 : </b> How to create Spring WebClient CRUD - GET, POST, PUT and DELETE Examples - [This article]</h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 2 : </b> <MyLink class='homelink' href='/4-ways-to-test-webclient-mocking' target='_blank'>" +
      "4 Ways to test WebClient using Mockito, MockWebServer, WebTestClient and WireMockServer</MyLink></h4>"
  },
  {
    type: "subheading",
    name: "spring-webclient-get-post-put-delete-example",
    title: "RestTemplate Alternative - Why ?"
  },
  {
    type: "text",
    content:
      "If you see the RestTemplate java docs, you will see this message: " +
      "<div class='lineBreak' /> " +
      "<code><i>NOTE: As of 5.0 this class is in maintenance mode, with only " +
      "minor requests for changes and bugs to be accepted going forward. Please, " +
      "consider using the org.springframework.web.reactive.client.WebClient which has " +
      "a more modern API and supports sync, async, and streaming scenarios.</i></code>" +
      "<div class='lineBreak' /> " +
      "This simply means RestTemplate is no longer the recommended way to use. Spring " +
      "recommends to use WebClient instead. Follow along for the step by step instructions on " +
      "how to use WebClient to do GET, POST, PUT and DELETE requests."
  },
  {
    type: "text",
    content: "<h4>1. Add dependencies in pom.xml</h4>"
  },
  {
    type: "text",
    content:
      "Let's start by bootstrapping our application using Spring Initializer by selecting <code>spring-boot-starter-webflux</code> dependency. " +
      "Although, not mandatory, we selected <code>devtools</code> and <code>lombok</code> to ease our development process."
  },
  {
    type: "gist",
    content: "b56ba61fd77b0ef5e66d38c3e589894b"
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h4>2. Create WebClient configuration</h4>"
  },
  {
    type: "text",
    content:
      "Create a WebClient object with the required configuration. In this example, " +
      "we will be calling a real world Rest API that is publicly available to everyone and free to use."
  },
  {
    type: "gist",
    content: "513b4cb3b8bf5f6c43f77b2e580d965c"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h4>3. Create model object</h4>"
  },
  {
    type: "gist",
    content: "1dc67aaa73af0e9a5ba54ab3345f7d18"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h4>4. Update the existing controller as below: </h4>"
  },
  {
    type: "gist",
    content: "6515b9f3848fb8f35fb48e0e4d5ed5ff"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "The above example shows GET, POST, PUT and DELETE examples using WebClient. " +
      "Don't get carried away by the result type: <code>Mono</code>. Its just a reactive way of " +
      "handling the results. <code>Mono</code> is used for handling single results while <code>Flux</code> is " +
      "for multiple results like a list of objects."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "The output of GET, POST, PUT and DELETE requests should be similar to below. "
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/spring-webclient-get-example.jpg"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/spring-webclient-post-example.jpg"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/spring-webclient-put-example.jpg"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/spring-webclient-delete-example.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "Now that we have a working real world API using WebClient, in our next post, we " +
      "will see how to test/mock this API using 4 different ways: <code>Mockito, MockWebServer, WebTestClient and WireMockServer</code>"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL:
      "/3-ways-to-convert-soapmessage-to-java-object-with-namespace/",
    previousText:
      "3 ways to convert SOAPMessage to Java Object with namespace and XML to SOAPMessage",
    nextURL: "/4-ways-to-test-webclient-mocking/",
    nextText:
      "4 Ways to test WebClient using Mockito, MockWebServer, WebTestClient and WireMockServer"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/SpringBootWebClient.zip"
  }
];

class WebClientCRUD extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />a
      </>
    );
  }
}

export default WebClientCRUD;
