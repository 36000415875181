import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { bookmarks } from "react-icons-kit/icomoon/bookmarks";

import Left from "components/layouts/Left.jsx";
import {
  getSearchResultsByCriteria,
  getFAIconByName
} from "components/common/CommonFunctions.jsx";
import GoogleAdContainer from "components/Ads/GoogleAdContainer";
import RenderIcon from "components/CustomWidgets/RenderIcon";
import CustomPagination from "components/CustomWidgets/CustomPagination";
import SocialMediaIcons from "components/CustomWidgets/SocialMediaIcons";
import Right from "components/layouts/Right.jsx";

function openBookmark(url, title, desc, authoredDate) {
  let label = authoredDate.substring(
    authoredDate.indexOf("-") + 1,
    authoredDate.length
  );
  let height = 475;
  let width = 550;
  let left = window.outerWidth / 2 + window.screenX - width / 2;
  let top = window.outerHeight / 2 + window.screenY - height / 2;
  let windowUrl =
    "http://www.google.com/bookmarks/mark?op=edit&output=popup&bkmk=" +
    window.location.protocol +
    "//" +
    window.location.host +
    url +
    "&title=" +
    title +
    "&annotation=" +
    desc +
    "&labels=" +
    label;
  window.open(
    windowUrl,
    "Bookmark",
    "left=" +
      left +
      ",top=" +
      top +
      ",height=" +
      height +
      ",width=" +
      width +
      ",toolbar=no,menubar=no,scrollbars=yes,resizable=yes,location=no,directories=no,status=no"
  );
}

function getData(offset, params) {
  let limit = 5;
  let offsetTmp = offset == null ? 1 : offset;
  // Add all non-existent hashtag mappings to existing hashtag below
  let criteriaMap = {
    "java-programming": "java",
    javascript: "react,angular",
    springboot: "spring"
  };
  let paramsTmp = "";
  // If non-existent hashtag, use the mapped value
  if (criteriaMap[params.criteria] !== undefined) {
    paramsTmp = {
      criteria: criteriaMap[params.criteria]
    };
  } else {
    // else use direct value
    paramsTmp = {
      criteria: params.criteria
    };
  }
  let dataTmp = getSearchResultsByCriteria(paramsTmp);
  let pageCountTmp = 0;
  let totalItemsCountTmp = 0;
  if (dataTmp) {
    totalItemsCountTmp = dataTmp.length;
    pageCountTmp = Math.ceil(dataTmp.length / limit);
    let startIndex = offsetTmp - 1;
    let start = offsetTmp == 1 ? 0 : limit * startIndex;
    dataTmp = dataTmp.slice(start, offsetTmp * limit);
  } else {
    dataTmp = "";
  }
  return {
    data: dataTmp,
    pageCount: pageCountTmp,
    totalItemsCount: totalItemsCountTmp
  };
}

class Search extends React.PureComponent {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      offset: 1
    };
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      offset: 1
    });
    if (this.customPaginationRef) {
      this.customPaginationRef.setActivePage(1);
    }
  }

  handleBookmark = urlContent => {
    openBookmark(
      urlContent.url,
      urlContent.title,
      urlContent.desc,
      urlContent.authoredDate
    );
  };

  handleFetchData = offset => {
    this.setState({
      offset: offset
    });
  };

  render() {
    const {
      match: { params }
    } = this.props;
    const { offset } = this.state;
    const { data, totalItemsCount } = getData(offset, params);
    return (
      <React.Fragment>
        <div className="header-bar"></div>
        <div className="container-fluid" style={{ minHeight: "650px" }}>
          <div className="row">
            <Left />
            <div className="col-md-8">
              <div>
                <h1>
                  #
                  {params.criteria === "java-programming"
                    ? "java"
                    : params.criteria}
                </h1>
              </div>
              <div>
                {data &&
                  data.map((urlContent, key) => (
                    <React.Fragment key={key}>
                      <div
                        className="rectangle post-heading"
                        style={{ boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)" }}
                      >
                        <div>
                          <table
                            className="table table-borderless table-paddingless"
                            style={{ maxWidth: "100%" }}
                          >
                            <tbody>
                              <tr>
                                <td colSpan="2" style={{ textAlign: "right" }}>
                                  <div
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    title="Bookmark"
                                  >
                                    <a
                                      className="CursorPointer"
                                      onClick={() =>
                                        this.handleBookmark(urlContent)
                                      }
                                      rel="noopener noreferrer"
                                      title="Bookmark"
                                      target="_blank"
                                    >
                                      <RenderIcon
                                        name={bookmarks}
                                        type={"icon"}
                                        color={"#ef6c00"}
                                        size={22}
                                      />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    margin: "0px",
                                    width: "15%",
                                    textAlign: "center"
                                  }}
                                >
                                  <RenderIcon
                                    name={
                                      urlContent.iconType === "faIcon"
                                        ? getFAIconByName(urlContent.icon)
                                        : urlContent.icon
                                    }
                                    type={urlContent.iconType}
                                    color={urlContent.iconColor}
                                    size={54}
                                  />
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <h5 className="authorLine1">
                                    {urlContent.authorName}
                                  </h5>
                                  <h6 className="authorLine2">
                                    {urlContent.authoredDate}
                                  </h6>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <Link className="dropdown-item" to={urlContent.url}>
                          <h2>{urlContent.title}</h2>
                        </Link>
                        <div className="displayTextLight">
                          {urlContent.desc.slice(0, 250) + "..."}
                        </div>
                        <div style={{ float: "left" }}>
                          <SocialMediaIcons
                            color="themed"
                            type="square"
                            size={28}
                          />
                        </div>
                        <div style={{ float: "right" }}>
                          <Link className="themedLink" to={urlContent.url}>
                            [<span className="darkLink">Read more...</span>]
                          </Link>
                        </div>
                        <br />
                      </div>
                      <br />
                    </React.Fragment>
                  ))}
              </div>
              <div style={{ textAlign: "center" }}>
                {data.length != 0 ? (
                  <CustomPagination
                    onRef={customPagination => {
                      this.customPaginationRef = customPagination;
                    }}
                    fetchData={offsetTmp => this.handleFetchData(offsetTmp)}
                    totalItemsCount={totalItemsCount}
                    limit={5}
                  />
                ) : (
                  <h4>
                    No results found. Please try any other topic or hash tag.
                  </h4>
                )}
              </div>
              <div>
                <div className="lineBreak" />
                <GoogleAdContainer position="square1" />
              </div>
            </div>
            <div className="col-md-2">
              <Right />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Search.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
};

export default Search;
