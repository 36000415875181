import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";

const header = {
  ...allHeaders["restfulClientUsingJersey"],
  hashTags: ["java", "springboot", "react", "webservices", "spring"],
  hashTagColors: ["blue", "warning", "info", "danger", "success"],
  channel: "12"
};

const data = [
  {
    type: "subheading",
    name: "restful-client-using-jersey",
    title: "Client"
  },
  {
    type: "text",
    content:
      "In our <MyLink class='homelink' href='/restful-webservice-using-jersey' target='_blank'>JAX-RS webservice using Jersey</MyLink> " +
      "tutorial, we have created a restful webservice that contains two methods. " +
      "Now, we will try to create a client and call the <code>ping()</code> method using Pure JAX-RS API without using Jersey and also using Jersey API." +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Our test case, HelloAngryWorldTest.java, has two methods, one using JAX-RS API and other using Jersey: " +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content: "<h4>1. HelloAngryWorldTest.java :</h4>"
  },
  {
    type: "gist",
    content: "42dc5d466bc99451588b12420f9379f8"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "There are some inter dependencies between the jar files being used for Pure JAX-RS client API and Jersey Client API. Hence, let us try to run each client separately. That is, to run a method, we need to comment out the other and viceversa." +
      "<div class='lineBreakSmall' /> " +
      "<code>testPingUsingPureJaxRS()</code> method uses pure JAX-RS classes for creating the response. Using pure JAX-RS API, the response creation will be as follows :" +
      "<div class='lineBreakSmall' /> " +
      "<b>Client -> WebTarget -> Invocation.Builder -> Response</b>" +
      "<div class='lineBreakSmall' /> " +
      "<code>testPingUsingJersey()</code> uses Jersey API, the response creation is as below :" +
      "<div class='lineBreakSmall' /> " +
      "<b>Client -> WebResource -> ClientResponse</b>" +
      "<div class='lineBreakSmall' /> "
  },
  {
    type: "subheading",
    name: "testing-the-webservice",
    title: "Testing the webservice:"
  },
  {
    type: "text",
    content:
      "There are number of ways you can test the Restful webservice. Before running any of the below, make sure the tomcat server is started and the webservice is running using," +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn clean package -DskipTests=true tomcat7:run</code> or <code>mvn clean package -DskipTests=true jetty:run</code>"
  },
  {
    type: "text",
    content:
      "<h4>1. Using maven command :</h4>" +
      "<div class='lineBreakSmall' /> " +
      "i. Specify the service.url at command line :" +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn -Dservice.url=http://localhost:&lt;port-number&gt;/jaxrs-service test</code>" +
      "<div class='lineBreakSmall' /> " +
      "This command uses a command line argument to specify the service.url. The service.url is used in the test case." +
      "<div class='lineBreakSmall' /> " +
      "ii. Specify the service.url in pom.xml"
  },
  {
    type: "gist",
    content: "0952c16398f22dba667d0efe6b909259"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' /> " +
      "Add the above configuration and use the below command to test the webservice," +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn test</code>" +
      "<div class='lineBreakSmall' /> " +
      "iii. Hard-code the service.url in HelloWorldTest.java and use the below command to run the tests." +
      "<div class='lineBreakSmall' /> " +
      "<code>mvn test</code>"
  },
  {
    type: "output",
    content:
      "\r-------------------------------------------------------" +
      "\rT E S T S" +
      "\r-------------------------------------------------------" +
      "\rRunning com.learninjava.HelloAngryWorldTest" +
      "\rResponse from web service is : Tweet" +
      "\rTests run: 1, Failures: 0, Errors: 0, Skipped: 0, Time elapsed: 43.997 sec\r" +
      "\rResults :\r" +
      "\rTests run: 1, Failures: 0, Errors: 0, Skipped: 0\r\n"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Now try to run the <code>testPingUsingJersey()</code> method by commenting the JAX-RS related configuration and enabling the Jersey API configuration. " +
      "You should see the same output as above. The idea is that you can use either way to create a Jersey Client." +
      "<div class='lineBreakSmall' /> "
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' /> " +
      "<h4>2. Using Poster plugin :</h4>" +
      "<div class='lineBreakSmall' /> " +
      "Poster is a plugin for firefox browser. This is a very useful and handy tool to test your restful webservices quickly." +
      "<div class='lineBreak' /> " +
      "i. To test the ping method of HelloAngryWorld, enter the following in Poster UI :" +
      "<div class='lineBreak' /> " +
      "<b>URL</b>: http://localhost:&lt;port-number&gt;/jaxrs-service/helloAngryWorld/echo/tweet" +
      "<div class='lineBreakSmall' /> " +
      "<b>Content Type</b>:   application/text" +
      "<div class='lineBreakSmall' /> " +
      "and click on GET button as ping is a GET request type. The response is displayed in the Response window."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/poster-text.png"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' /> " +
      "ii. To test the <code>modifyJson()</code> method of HelloAngryWorld, enter the following in Poster UI :" +
      "<div class='lineBreakSmall' /> " +
      "<b>URL</b>: http://localhost:&lt;port-number&gt;/jaxrs-service/helloAngryWorld/jsonBean" +
      "<div class='lineBreakSmall' /> " +
      "<b>Content Type</b>:   application/json" +
      "<div class='lineBreakSmall' /> " +
      "<b>Payload(Text Box at bottom)</b>:  { &quot;val1&quot;: &quot;Birds&quot;, &quot;val2&quot;: &quot;Eggs&quot; }" +
      "<div class='lineBreakSmall' /> " +
      "and click on POST button as modifyJson is a POST request type. The response is displayed in the Response window."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/poster-json.png"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/restful-webservice-using-cxf/",
    previousText: "JAX-RS Restful WebService using CXF",
    nextURL: "/restful-webservice-using-jersey/",
    nextText: "JAX-RS Restful WebService using Jersey"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/jerseyjaxrsws.zip"
  }
];

class RestfulClientUsingJersey extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default RestfulClientUsingJersey;
