import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["reactWidgetPackDisqus"],
  ...allHashTags,
  channel: "15"
};

const data = [
  {
    type: "subheading",
    name: "introduction",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "Have you used Disqus commenting system ? Undoubtedly, It is pretty easy to use application. " +
      "However, it comes with a price, its not free. Even the Basic plan is Ad Supported, meaning, " +
      "your commenting section will be filled with Ads. This does not look user friendly when your visitors " +
      "want to comment on something interesting. " +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "These are exactly the same issues we faced when this website is being setup. Can you believe that you " +
      "can setup your commenting system by just including a single component ? That too with just a single line " +
      "to import and another line to use it ? " +
      "Does it amaze you if that commenting system is free for one website with full fledged feature list ? " +
      "<div class='lineBreak' /> " +
      "We felt exactly the same. Well !! it was not easy for us to create a React Component that can be reusuable. " +
      "OK, that application is called WidgetPack. It comes with a lot of other widgets too but we are going to cover only " +
      "comments component for now. We made it more easier for you to use WidgetPack comments by creating an npm module named : " +
      "<a class='homelink' href='https://www.npmjs.com/package/react-widgetpack-comments' target='_blank'><span>react-widgetpack-comments</span></MyLink>" +
      "<div class='lineBreakSmall' /> " +
      "Follow step by step instructions below: "
  },
  {
    type: "text",
    content:
      "<h4>1. Register with widgetpack: </h4>" +
      "<div class='lineBreak' /> " +
      "First step is to register with widgetpack here: " +
      "<a class='homelink' href='https://widgetpack.com/' target='_blank'><span>WidgetPack</span></MyLink>" +
      "<div class='lineBreak' /> " +
      "Once registered, Navigate to Site > Install and you will see the above screen with some code. " +
      "The redacted section will have your unique id. You can think of this id as a publisher id that is unique for every registered user." +
      "<div class='lineBreak' /> "
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/widgetpack-comments6.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The code is straight forward if you are using plain HTML. But you need to do a lot of sweating to make " +
      "it work with React. This is where <code>react-widgetpack-comments</code> comes into picture. But, more on that later." +
      "<div class='lineBreak' /> " +
      "The user interface of widgetpack is very user friendly and pretty basic. You can Approve, Reject, Delete any comments as a moderator. " +
      "The free plan offers one moderator. Below is a screenprint of the moderator's user interface."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/widgetpack-comments5.jpg"
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>2. Install &quot;react-widgetpack-comments&quot; npm module: </h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Install the npm module using the below command: " +
      "<div class='lineBreakSmall' /> " +
      "<code>npm i react-widgetpack-comments</code>"
  },
  {
    type: "text",
    content: "<h4>3. Import module and use it:  </h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Import the component as: " +
      "<div class='lineBreakSmall' /> " +
      "<code>import ReactWidgetPackComments from 'react-widgetpack-comments';</code>" +
      "<div class='lineBreakSmall' /> " +
      "Include the component as: " +
      "<div class='lineBreakSmall' /> " +
      "<code>&lt;ReactWidgetPackComments id={unique-id} enable={true} /&gt;</code>" +
      "<div class='lineBreakSmall' /> "
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "1. <b>unique-id</b> - is the unique id assigned by widget pack when account is created" +
          "<div class='lineBreakSmall' /> " +
          "2. <b>enable</b> - true to enable the component, false otherwise. This parameter is useful " +
          "while working in development environment. You dont want someone accidentally entering comments." +
          "<div class='lineBreakSmall' /> " +
          "<code>Note:</code> No need to worry about page specific comments, WidgetPack will take care of that for you " +
          "depending on the URL. As long as the URLs are unique, you will get page specific comments."
      }
    ]
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h4>4. Run the application:  </h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' />" +
      "We have created a demo application. Find the source in the &quot;Download Source&quot; at the bottom of this page. " +
      "When you run the demo application, you should see something like below, " +
      "<div class='lineBreak' />"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/widgetpack-comments1.jpg"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "commenting",
    title: "Commenting"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' />" +
      "In order to post your comments, click on the &quot;Login&quot; link. You should see a screen with all the available social media login options. " +
      "As a moderator, you can control which social media options needs to be enabled or disabled to your website users as per your choice."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/widgetpack-comments2.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "If you select google for instance, you should be directed to the login screen as below:"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/widgetpack-comments3.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "Now, go ahead and post your comment. Once posted, your comments will appear as below:"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/widgetpack-comments4.jpg"
  },
  {
    type: "mediaAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "conclusion",
    title: "Conclusion"
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' />" +
      "WidgetPack comments makes your life easier by reducing your development time in setting up a commenting system quickly for your website. " +
      "We are not saying to promote it. We are infact using it and wanted to share our experience." +
      "<div class='lineBreak' />" +
      "This screen also uses the same so go ahead and post your first comments " +
      "and try out the commenting system at the bottom of the screen. While doing so, let us know your thoughts and experience as well. Happy commenting !!" +
      "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/react-proxying-api-backend-apache-nginx-tomcat/",
    previousText:
      "React Proxy Backend API configuration - Apache Http Server, Nginx, Tomcat",
    nextURL: "/create-react-app-react-router/",
    nextText:
      "React Router Configuration - Create React App - Step by Step Configuration"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/react-widget-pack-app.zip"
  }
];

class ReactWidgetPackDisqusComments extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default ReactWidgetPackDisqusComments;
