const headerAttrs = {
  stream: {
    title: "Java 8 - Stream",
    metaTitle:
      "Java Streams | Java 8 Streams | When to Use Java Streams - Tutorial",
    desc:
      "Java 8 has introduced a new concept called Stream. These are a " +
      "group of computations specified as a sequence of steps. Although, " +
      "the name sounds similar to Java IO API classes like InputStream/OutputStream, " +
      "this has nothing to do with them",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url: "https://www.learninjava.com/java8-stream"
  },
  functionalInterfaces: {
    title: "Java 8 - Functional Interface",
    metaTitle:
      "Functional Interfaces in Java 8 | Java Functional Interface Tutorial",
    desc:
      "A functional interface is any interface with only one method in it. Java 8 " +
      "has introduced a new annotation called @FunctionalInterface. This annotation is " +
      "used to check for compile time type safety on functional interfaces",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url: "https://www.learninjava.com/java8-functional-interfaces"
  },
  lambdaExpressions: {
    title: "Java 8 - Lambda Expressions",
    metaTitle: "Lambda Expressions in Java | Java Lambda Expressions Tutorial",
    desc:
      "Java 8 Lambda Expressions treats functionality as method arguments or code as data. " +
      "Very difficult definition to understand ? For Me too!! Simply said, lambda expressions " +
      "simplifies the syntax when we have only one method in an interface which needs to be called",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url: "https://www.learninjava.com/java8-lambda-expressions"
  },
  equalsAndHashCodeOverride: {
    title: "Equals and HashCode Override",
    metaTitle: "Why You Should Override Equals and HashCode in Java?",
    desc:
      "The reason why you should override hashcode whenever you override equals is that " +
      "you need a way to create the same object you want to find. This is particularly " +
      "useful when you are dealing with collections.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/equals-and-hashcode.jpg",
    url: "https://www.learninjava.com/why-equals-and-hashcode-override"
  },
  threadStates: {
    title: "Thread States - Life cycle of a Thread",
    metaTitle:
      "What are the different Thread States in Java - Life cycle of a Thread",
    desc:
      "Java Thread States explained in a simple and easy to understand approach. Blocked state " +
      "is where we see the threads awaiting for some response. A thread can be in waiting " +
      "state when it requires a lock but is currently being used by some other thread",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/thread-states.jpg",
    url: "https://www.learninjava.com/thread-states"
  },
  userThreadDaemonThread: {
    title: "User and Daemon Threads",
    metaTitle:
      "What are User Threads and Daemon Threads in Java | Learn User and Daemon Threads",
    desc:
      "A User thread is a thread that is created by the User i.e, the application where " +
      "as a Daemon thead is a thread that is created to serve the user thread. A Daemon " +
      "thread is usually made to run in the background continuously",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url: "https://www.learninjava.com/user-thread-and-daemon-thread"
  },
  restfulWebserviceUsingCXF: {
    title: "JAX-RS Restful WebService using CXF",
    metaTitle:
      "Restful Web Service Using Java JAX-RS, CXF | Creating JAX-RS Web Service using CXF with Example",
    desc:
      "This tutorial explains how to create a JAX-RS restful " +
      "webservice using Spring and CXF. For your convenience, we " +
      "have provided configurations for running the webservice on both " +
      "Jetty and Tomcat servers.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/webservices-banner.jpg",
    url: "https://www.learninjava.com/restful-webservice-using-cxf"
  },
  restfulClientUsingCXF: {
    title: "JAX-RS Restful Client using CXF",
    metaTitle:
      "Restful Client Using Java JAX-RS, CXF | Creating JAX-RS Client using CXF with Example",
    desc:
      "This tutorial explains how to create a JAX-RS restful " +
      "client using Spring and CXF. In our JAX-RS webservice using CXF " +
      "tutorial, we have created a restful webservice that contains two methods. " +
      "One method accepts text type input while the second one accepts a JsonBean type",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/poster-json.png",
    url: "https://www.learninjava.com/restful-client-using-cxf"
  },
  jaxwsWebserviceUsingCXF: {
    title: "JAX-WS SOAP WebService using CXF",
    metaTitle:
      "WS SOAP Web Service Using Java CXF | Creating JAX-WS service using CXF with Example",
    desc:
      "This tutorial explains how to create a JAX-WS SOAP based " +
      "webservice using Spring and CXF. For your convenience, we " +
      "have provided configurations for running the webservice on both Jetty and Tomcat servers.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/webservices-banner.jpg",
    url: "https://www.learninjava.com/jaxws-soap-webservice-using-cxf"
  },
  jaxwsClientUsingCXF: {
    title: "JAX-WS SOAP Client using CXF",
    metaTitle:
      "WS SOAP Client Using Java CXF | Creating JAX-WS Client using CXF with Example",
    desc:
      "This tutorial explains how to create a JAX-WS SOAP based " +
      "client using Spring and CXF. There are number of ways you can test this SOAP webservice.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/jaxws-wizdler1.png",
    url: "https://www.learninjava.com/jaxws-soap-client-using-cxf"
  },
  restfulWebserviceUsingJersey: {
    title: "JAX-RS Restful WebService using Jersey",
    metaTitle:
      "RS Restful Web Service using Jersey | REST with Java (JAX-RS) using Jersey - Tutorial with Example",
    desc:
      "This tutorial explains how to create a JAX-RS Restful " +
      "webservice using Spring and Jersey. For your convenience, " +
      "we have provided configurations for running the webservice on " +
      "both Jetty and Tomcat servers.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/webservices-banner.jpg",
    url: "https://www.learninjava.com/restful-webservice-using-jersey"
  },
  restfulClientUsingJersey: {
    title: "JAX-RS Restful Client using Jersey",
    metaTitle:
      "RS Restful Client using Jersey | Restful Client with Java (JAX-RS) using Jersey - Tutorial with Example",
    desc:
      "This tutorial explains how to create a JAX-RS Restful " +
      "client using Spring and Jersey. We will try to create a client and " +
      "call the ping() method using Pure JAX-RS API without using Jersey and " +
      "also using Jersey API.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/poster-text.png",
    url: "https://www.learninjava.com/restful-client-using-jersey"
  },
  reactRouterApacheNginxTomcat: {
    title: "React Router Configuration - Apache Http Server, Nginx, Tomcat",
    metaTitle:
      "React Router: Router Configuration | How to React Routing with Apache Http Server, Nginx, Tomcat",
    desc:
      "This React post explains how to configure react router on Apache HTTP Server, " +
      "Nginx Server and Tomcat Server. The react post explains client-side and server-side " +
      "routing and production ready config on three most famous servers: Apache HTTP Server, Nginx and Tomcat." +
      "Difference between BrowserRouter and HashRouter explained as well. Also has working" +
      "example and downloadable source code.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-router.jpg",
    url: "https://www.learninjava.com/react-router-apache-nginx-tomcat"
  },
  createReactAppReactRouter: {
    title:
      "React Router Configuration - Create React App - Step by Step Configuration",
    metaTitle:
      "Step by Step Tutorial for React Router Configuration on Create New React(CRA) App: ",
    desc:
      "This React post explains how to configure react router on create-react-app (CRA) " +
      "step by step with a working example and downloadable source code included." +
      "The working react sample application is easy to understand.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/react-routing-banner.jpg",
    url: "https://www.learninjava.com/create-react-app-react-router"
  },
  reactWidgetPackDisqus: {
    title:
      "React WidgetPack Comments - A Free Disqus alternative - Step by Step",
    metaTitle:
      "React WidgetPack Comments - A Free Disqus alternative - Step by Step",
    desc:
      "This react tutorial explains how to use widgetpack's comments widget as a free alternative to " +
      "disqus commenting system using React. The example application is provided for easy understanding. " +
      "It uses react-widgetpack-comments npm module. A full Commenting system can be implemented on your " +
      "website with just 1 or 2 lines of code.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/widgetpack-comments4.jpg",
    url: "https://www.learninjava.com/react-widgetpack-free-disqus-alternative"
  },
  angularRouterApacheNginxTomcat: {
    title: "Angular Router Configuration - Apache Http Server, Nginx, Tomcat",
    metaTitle:
      "Angular Router Configuration - Angular Routing on Apache Http Server, Nginx, Tomcat",
    desc:
      "This Angular post explains how to configure angular router on Apache HTTP Server, " +
      "Nginx Server and Tomcat Server. The angular post explains client-side and server-side " +
      "routing and production ready config on three most famous servers: Apache HTTP Server, Nginx and Tomcat." +
      "Difference between HashLocationStrategy and PathLocationStrategy explained as well. Also has working" +
      "example and downloadable source code.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/angular-router-banner.jpg",
    url: "https://www.learninjava.com/angular-router-config-apache-nginx-tomcat"
  },
  angularRouterConfigCLIApp: {
    title: "Angular Router Configuration - Angular CLI App - Step by Step",
    metaTitle:
      "Step by Step Tutorial for Angular Router Configuration on Angular CLI App",
    desc:
      "This Angular tutorial explains how to configure angular router on Angular CLI, " +
      "generate application. The tutorial explains how to import @angular/router module and " +
      "configure the ng new basic application step by step.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/angular-router-banner.jpg",
    url: "https://www.learninjava.com/angular-router-config-cli-generate-app"
  },
  reactProxyApacheNginxTomcat: {
    title:
      "React Proxy Backend API configuration - Apache Http Server, Nginx, Tomcat",
    metaTitle:
      "React Proxy Backend API configuration | How To Proxy To React Backend API configuration in Apache Http Server, Nginx, Tomcat",
    desc:
      "This React post explains how to proxy backend API requests in react on Apache HTTP Server, " +
      "Nginx Server and Tomcat Server. The react post explains how to setup API proxy configuration " +
      "on create-react-app(CRA) application step by step. The proxy configuration is production ready and working.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/react-api-proxying-banner.jpg",
    url:
      "https://www.learninjava.com/react-proxying-api-backend-apache-nginx-tomcat"
  },
  reactReduxConfig: {
    title:
      "React Redux Configuration - How to configure React with Redux and Thunk ?",
    metaTitle:
      "React Redux Configuration | How to configure React with Redux and Thunk ?",
    desc:
      "This React post explains how to configure react and redux in a simple and easy to understand " +
      "approach. Explains what are Actions, Action Types, Reducers ? and what is Thunk middleware ? " +
      "The post includes working example which is production ready configuration.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/react-redux-banner.jpg",
    url: "https://www.learninjava.com/how-to-configure-react-and-redux"
  },
  soapMessageToObject: {
    title:
      "3 ways to convert SOAPMessage to Java Object with namespace and " +
      "XML to SOAPMessage",
    metaTitle:
      "3 ways to convert SOAPMessage to Java Object with namespace and " +
      "XML to SOAPMessage",
    desc:
      "This article provides one stop guide to convert SOAPMessage to Java Object with namespaces, " +
      "SOAPMessage as XML to SOAPMessage Object using Jaxb2Marshaller, " +
      "XMLStreamReader, JAXBContext and Document APIs",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/webservices-banner.jpg",
    url:
      "https://www.learninjava.com/3-ways-to-convert-soapmessage-to-java-object-with-namespace"
  },
  reactTableWithPagination: {
    title:
      "React Table - How to create a simple react table with pagination and sorting",
    metaTitle:
      "React Table - How to create a simple react table with pagination and sorting in ascending and " +
      "descending order",
    desc:
      "This tutorial explains with example, how to create a simple react table with pagination and sorting in ascending and " +
      "descending order in quick and easy step by step approach.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-table.jpg",
    url:
      "https://www.learninjava.com/how-to-create-react-table-with-pagination-and-sorting"
  },
  reactLoadingSpinnerWithOverlay: {
    title: "Guide to create React Loading Spinner with Overlay",
    metaTitle: "React Loading Spinner with Overlay - Guide",
    desc:
      "This tutorial explains with examples, how to create a simple loading spinner using react " +
      "and font-awesome components with and without using an overlay created with react-loading-overlay component.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-loading-spinner2.jpg",
    url: "https://www.learninjava.com/react-loading-spinner-with-overlay"
  },
  reactTableWithLoadingSpinner: {
    title: "React Table - How to add a loading spinner",
    metaTitle: "How to add a loading spinner to React Table",
    desc:
      "This tutorial explains with working code, how to add a loading spinner to " +
      "react table.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-table-with-loading-spinner.jpg",
    url: "https://www.learninjava.com/react-table-with-loading-spinner"
  },
  webClientCRUD: {
    title:
      "How to create Spring WebClient CRUD - GET, POST, PUT and DELETE Examples",
    metaTitle:
      "How to create Spring WebClient CRUD - GET, POST, PUT and DELETE Examples",
    desc:
      "Spring WebClient - GET, PUT, POST, DELETE examples: Learn how to create, read, " +
      "update and delete using WebClient, an alternative to RestTemplate, examples " +
      "using GET, POST, PUT and DELETE http methods.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/spring-webclient-get-example.jpg",
    url:
      "https://www.learninjava.com/spring-webclient-get-post-put-delete-example"
  },
  webClientTesting: {
    title:
      "4 Ways to test WebClient using Mockito, MockWebServer, WebTestClient and WireMockServer",
    metaTitle:
      "4 Ways to test WebClient using Mockito, MockWebServer, WebTestClient and WireMockServer",
    desc:
      "Mocking a WebClient in Spring: Learn how to test WebClient, examples include " +
      "testing using Mockito, MockWebServer, WebTestClient and WireMockServer.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/webclient-testing-mockito.jpg",
    url: "https://www.learninjava.com/4-ways-to-test-webclient-mocking"
  },
  reactTableWithRecordCount: {
    title: "React Table - How to add a Record Count",
    metaTitle: "How to add a Record Count to React Table",
    desc:
      "This tutorial explains with working code, how to add a record count to " +
      "react table.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-table-with-record-count.png",
    url: "https://www.learninjava.com/react-table-with-record-count"
  },
  reactTableWithNestedSubTable: {
    title: "React Table - How to create a nested sub table",
    metaTitle: "React Table - How to create a nested sub table",
    desc:
      "This tutorial explains with working code, how to create a nested child sub table " +
      "inside a react table.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-table-with-nested-subtable.jpg",
    url: "https://www.learninjava.com/react-table-with-nested-subtable"
  },
  easyGuideToSSL: {
    title: "Easy Guide to SSL - All the terms you need to know",
    metaTitle: "Easy Guide to SSL - All the terms you need to know",
    desc:
      "A simple and easy guide to SSL concepts and all the terminology involved in SSL." +
      "The most confusing terms in SSL are explained in an easy to understand way.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url: "https://www.learninjava.com/easy-guide-to-ssl"
  },
  pkcsAndPemToJks: {
    title: "Convert PKCS#12 to JKS and PEM to JKS format",
    metaTitle: "Convert PKCS#12 to JKS and PEM to JKS format",
    desc:
      "A simple and easy guide to convert PKCS#12(Public Key Cryptography Standards) " +
      "to JKS(Java Key Store) and PEM(Privacy Enhanced Mail) to JKS(Java Key Store) format.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url: "https://www.learninjava.com/convert-p12-to-jks-and-pem-to-jks"
  },
  tlsMutualAuth: {
    title:
      "Two way SSL/Mutual Authentication - How to use client certificate in Postman and SOAP UI",
    metaTitle:
      "Two way SSL/Mutual Authentication - How to use client certificate in Postman and SOAP UI",
    desc:
      "Demonstrates how to configure and use TLS Mutual Authentication/Two way SSL Authentication/" +
      "Client Certificates in POSTMAN and SOAP UI.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url:
      "https://www.learninjava.com/tls-mutual-auth-two-way-ssl-postman-soapui"
  }
};

const allHeaders = {
  stream: {
    category: "java-banner",
    title: headerAttrs.stream.title,
    metaTitle: headerAttrs.stream.metaTitle,
    authorImage: "fab-java",
    iconColor: "#086eb6",
    authorName: "learninjava",
    authoredDate: "Jan 11, 2015 - Java 8",
    meta: [
      {
        type: "description",
        content:
          "Learn Java 8 streams by example: Java 8 has introduced a new concept called Stream. Learn more about streams in Java."
      },
      {
        type: "og:title",
        content: headerAttrs.stream.title
      },
      {
        type: "og:description",
        content: headerAttrs.stream.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.stream.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.stream.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.stream.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.stream.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.stream.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.stream.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.stream.imgUrl
      }
    ]
  },
  functionalInterfaces: {
    category: "java-banner",
    title: headerAttrs.functionalInterfaces.title,
    metaTitle: headerAttrs.functionalInterfaces.metaTitle,
    authorImage: "fab-java",
    iconColor: "#086eb6",
    authorName: "learninjava",
    authoredDate: "Apr 15, 2016 - Java 8",
    meta: [
      {
        type: "description",
        content:
          "Functional interfaces: A functional interface in Java 8 is any interface with only one method in it. Learn Java Functional Interface with examples."
      },
      {
        type: "og:title",
        content: headerAttrs.functionalInterfaces.title
      },
      {
        type: "og:description",
        content: headerAttrs.functionalInterfaces.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.functionalInterfaces.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.functionalInterfaces.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.functionalInterfaces.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.functionalInterfaces.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.functionalInterfaces.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.functionalInterfaces.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.functionalInterfaces.imgUrl
      }
    ]
  },
  lambdaExpressions: {
    category: "java-banner",
    title: headerAttrs.lambdaExpressions.title,
    metaTitle: headerAttrs.lambdaExpressions.metaTitle,
    authorImage: "fab-java",
    iconColor: "#086eb6",
    authorName: "learninjava",
    authoredDate: "March 18, 2015 - Java 8",
    meta: [
      {
        type: "description",
        content:
          "Lambda Expressions treats functionality as method arguments or code as data. Simply, lambda expressions simplifies the syntax when we have only one method in an interface which needs to be called. Learn more."
      },
      {
        type: "og:title",
        content: headerAttrs.lambdaExpressions.title
      },
      {
        type: "og:description",
        content: headerAttrs.lambdaExpressions.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.lambdaExpressions.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.lambdaExpressions.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.lambdaExpressions.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.lambdaExpressions.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.lambdaExpressions.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.lambdaExpressions.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.lambdaExpressions.imgUrl
      }
    ]
  },
  equalsAndHashCodeOverride: {
    category: "java-banner",
    title: headerAttrs.equalsAndHashCodeOverride.title,
    metaTitle: headerAttrs.equalsAndHashCodeOverride.metaTitle,
    authorImage: "fab-java",
    iconColor: "#086eb6",
    authorName: "learninjava",
    authoredDate: "Jan 11, 2015 - Java",
    meta: [
      {
        type: "description",
        content:
          "The reason why you should override hashCode() whenever you override equals(Object) is that you need a way to create the same object you want to find. Read more."
      },
      {
        type: "og:title",
        content: headerAttrs.equalsAndHashCodeOverride.title
      },
      {
        type: "og:description",
        content: headerAttrs.equalsAndHashCodeOverride.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.equalsAndHashCodeOverride.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.equalsAndHashCodeOverride.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.equalsAndHashCodeOverride.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.equalsAndHashCodeOverride.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.equalsAndHashCodeOverride.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.equalsAndHashCodeOverride.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.equalsAndHashCodeOverride.imgUrl
      }
    ]
  },
  threadStates: {
    category: "java-banner",
    title: headerAttrs.threadStates.title,
    metaTitle: headerAttrs.threadStates.metaTitle,
    authorImage: "fab-java",
    iconColor: "#086eb6",
    authorName: "learninjava",
    authoredDate: "Mar 24, 2015 - Java",
    meta: [
      {
        type: "description",
        content: headerAttrs.threadStates.desc
      },
      {
        type: "og:title",
        content: headerAttrs.threadStates.title
      },
      {
        type: "og:description",
        content: headerAttrs.threadStates.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.threadStates.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.threadStates.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.threadStates.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.threadStates.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.threadStates.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.threadStates.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.threadStates.imgUrl
      }
    ]
  },
  userThreadDaemonThread: {
    category: "java-banner",
    title: headerAttrs.userThreadDaemonThread.title,
    metaTitle: headerAttrs.userThreadDaemonThread.metaTitle,
    authorImage: "fab-java",
    iconColor: "#086eb6",
    authorName: "learninjava",
    authoredDate: "Mar 24, 2015 - Java",
    meta: [
      {
        type: "description",
        content:
          "Java offers two types of threads, user threads and daemon threads, read more to learn."
      },
      {
        type: "og:title",
        content: headerAttrs.userThreadDaemonThread.title
      },
      {
        type: "og:description",
        content: headerAttrs.userThreadDaemonThread.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.userThreadDaemonThread.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.userThreadDaemonThread.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.userThreadDaemonThread.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.userThreadDaemonThread.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.userThreadDaemonThread.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.userThreadDaemonThread.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.userThreadDaemonThread.imgUrl
      }
    ]
  },
  restfulWebserviceUsingCXF: {
    category: "webservices-banner",
    title: headerAttrs.restfulWebserviceUsingCXF.title,
    metaTitle: headerAttrs.restfulWebserviceUsingCXF.metaTitle,
    authorImage: "fas-cogs",
    iconColor: "#416284",
    authorName: "learninjava",
    authoredDate: "Aug 06, 2015 - Webservices",
    meta: [
      {
        type: "description",
        content:
          "JAX-RS web service using CXF: CXF supports the Java for Restful Web Services. Let us look at an example on how to create a simple restful webservice using CXF."
      },
      {
        type: "og:title",
        content: headerAttrs.restfulWebserviceUsingCXF.title
      },
      {
        type: "og:description",
        content: headerAttrs.restfulWebserviceUsingCXF.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.restfulWebserviceUsingCXF.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.restfulWebserviceUsingCXF.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.restfulWebserviceUsingCXF.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.restfulWebserviceUsingCXF.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.restfulWebserviceUsingCXF.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.restfulWebserviceUsingCXF.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.restfulWebserviceUsingCXF.imgUrl
      }
    ]
  },
  restfulClientUsingCXF: {
    category: "webservices-banner",
    title: headerAttrs.restfulClientUsingCXF.title,
    metaTitle: headerAttrs.restfulClientUsingCXF.metaTitle,
    authorImage: "fas-cogs",
    iconColor: "#416284",
    authorName: "learninjava",
    authoredDate: "Aug 06, 2015 - Webservices",
    meta: [
      {
        type: "description",
        content:
          "JAX-RS Client using CXF: CXF supports the Java for Restful client. Let us look at an example on how to create a simple restful client using CXF."
      },
      {
        type: "og:title",
        content: headerAttrs.restfulClientUsingCXF.title
      },
      {
        type: "og:description",
        content: headerAttrs.restfulClientUsingCXF.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.restfulClientUsingCXF.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.restfulClientUsingCXF.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.restfulClientUsingCXF.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.restfulClientUsingCXF.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.restfulClientUsingCXF.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.restfulClientUsingCXF.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.restfulClientUsingCXF.imgUrl
      }
    ]
  },
  jaxwsWebserviceUsingCXF: {
    category: "webservices-banner",
    title: headerAttrs.jaxwsWebserviceUsingCXF.title,
    metaTitle: headerAttrs.jaxwsWebserviceUsingCXF.metaTitle,
    authorImage: "fas-cogs",
    iconColor: "#416284",
    authorName: "learninjava",
    authoredDate: "Apr 26, 2015 - Webservices",
    meta: [
      {
        type: "description",
        content:
          "WS SOAP Web Service using CXF: CXF supports the Java for WS SOAP Web Service. Let us look at an example on how to create a simple WS SOAP Web Service using CXF."
      },
      {
        type: "og:title",
        content: headerAttrs.jaxwsWebserviceUsingCXF.title
      },
      {
        type: "og:description",
        content: headerAttrs.jaxwsWebserviceUsingCXF.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.jaxwsWebserviceUsingCXF.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.jaxwsWebserviceUsingCXF.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.jaxwsWebserviceUsingCXF.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.jaxwsWebserviceUsingCXF.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.jaxwsWebserviceUsingCXF.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.jaxwsWebserviceUsingCXF.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.jaxwsWebserviceUsingCXF.imgUrl
      }
    ]
  },
  jaxwsClientUsingCXF: {
    category: "webservices-banner",
    title: headerAttrs.jaxwsClientUsingCXF.title,
    metaTitle: headerAttrs.jaxwsClientUsingCXF.metaTitle,
    authorImage: "fas-cogs",
    iconColor: "#416284",
    authorName: "learninjava",
    authoredDate: "Aug 06, 2015 - Webservices",
    meta: [
      {
        type: "description",
        content:
          "WS SOAP Client using CXF: CXF supports the Java for WS SOAP Client. Let us look at an example on how to create a simple WS SOAP client using CXF."
      },
      {
        type: "og:title",
        content: headerAttrs.jaxwsClientUsingCXF.title
      },
      {
        type: "og:description",
        content: headerAttrs.jaxwsClientUsingCXF.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.jaxwsClientUsingCXF.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.jaxwsClientUsingCXF.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.jaxwsClientUsingCXF.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.jaxwsClientUsingCXF.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.jaxwsClientUsingCXF.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.jaxwsClientUsingCXF.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.jaxwsClientUsingCXF.imgUrl
      }
    ]
  },
  restfulWebserviceUsingJersey: {
    category: "webservices-banner",
    title: headerAttrs.restfulWebserviceUsingJersey.title,
    metaTitle: headerAttrs.restfulWebserviceUsingJersey.metaTitle,
    authorImage: "fas-cogs",
    iconColor: "#416284",
    authorName: "learninjava",
    authoredDate: "Aug 06, 2015 - Webservices",
    meta: [
      {
        type: "description",
        content:
          "Jersey Restful Web Services:  Jersey supports the Java for WS SOAP Client. Let us look at an example on how to create a simple RS Restful Web Service using Jersey."
      },
      {
        type: "og:title",
        content: headerAttrs.restfulWebserviceUsingJersey.title
      },
      {
        type: "og:description",
        content: headerAttrs.restfulWebserviceUsingJersey.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.restfulWebserviceUsingJersey.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.restfulWebserviceUsingJersey.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.restfulWebserviceUsingJersey.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.restfulWebserviceUsingJersey.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.restfulWebserviceUsingJersey.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.restfulWebserviceUsingJersey.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.restfulWebserviceUsingJersey.imgUrl
      }
    ]
  },
  restfulClientUsingJersey: {
    category: "webservices-banner",
    title: headerAttrs.restfulClientUsingJersey.title,
    metaTitle: headerAttrs.restfulClientUsingJersey.metaTitle,
    authorImage: "fas-cogs",
    iconColor: "#416284",
    authorName: "learninjava",
    authoredDate: "Aug 06, 2015 - Webservices",
    meta: [
      {
        type: "description",
        content:
          "Jersey Restful Client: Jersey supports the Java for WS SOAP Client. Let us look at an example on how to create a simple RS Restful client using Jersey."
      },
      {
        type: "og:title",
        content: headerAttrs.restfulClientUsingJersey.title
      },
      {
        type: "og:description",
        content: headerAttrs.restfulClientUsingJersey.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.restfulClientUsingJersey.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.restfulClientUsingJersey.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.restfulClientUsingJersey.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.restfulClientUsingJersey.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.restfulClientUsingJersey.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.restfulClientUsingJersey.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.restfulClientUsingJersey.imgUrl
      }
    ]
  },
  reactRouterApacheNginxTomcat: {
    category: "react-routing-banner",
    title: headerAttrs.reactRouterApacheNginxTomcat.title,
    metaTitle: headerAttrs.reactRouterApacheNginxTomcat.metaTitle,
    authorImage: "fab-react",
    iconColor: "#00d8fb",
    authorName: "learninjava",
    authoredDate: "Apr 18, 2020 - ReactJS",
    meta: [
      {
        type: "description",
        content:
          "How to React Router Configuration: The actual concepts of routing along with production ready configuration on three most famous servers: Apache HTTP Server, Nginx and Tomcat. Read more."
      },
      {
        type: "og:title",
        content: headerAttrs.reactRouterApacheNginxTomcat.title
      },
      {
        type: "og:description",
        content: headerAttrs.reactRouterApacheNginxTomcat.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.reactRouterApacheNginxTomcat.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.reactRouterApacheNginxTomcat.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.reactRouterApacheNginxTomcat.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.reactRouterApacheNginxTomcat.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.reactRouterApacheNginxTomcat.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.reactRouterApacheNginxTomcat.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.reactRouterApacheNginxTomcat.imgUrl
      }
    ]
  },
  createReactAppReactRouter: {
    category: "react-routing-banner",
    title: headerAttrs.createReactAppReactRouter.title,
    metaTitle: headerAttrs.createReactAppReactRouter.metaTitle,
    authorImage: "fab-react",
    iconColor: "#00d8fb",
    authorName: "learninjava",
    authoredDate: "Apr 28, 2020 - ReactJS",
    meta: [
      {
        type: "description",
        content:
          "Creating React App: Configuring react router is probably the most painful things in react. We will try to step by step tutorial this process. So follow along with this easy to understand."
      },
      {
        type: "og:title",
        content: headerAttrs.createReactAppReactRouter.title
      },
      {
        type: "og:description",
        content: headerAttrs.createReactAppReactRouter.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.createReactAppReactRouter.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.createReactAppReactRouter.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.createReactAppReactRouter.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.createReactAppReactRouter.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.createReactAppReactRouter.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.createReactAppReactRouter.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.createReactAppReactRouter.imgUrl
      }
    ]
  },
  reactWidgetPackDisqus: {
    category: "react-banner",
    title: headerAttrs.reactWidgetPackDisqus.title,
    metaTitle: headerAttrs.reactWidgetPackDisqus.metaTitle,
    authorImage: "fab-react",
    iconColor: "#00d8fb",
    authorName: "learninjava",
    authoredDate: "May 05, 2020 - ReactJS",
    meta: [
      {
        type: "description",
        content: headerAttrs.reactWidgetPackDisqus.desc
      },
      {
        type: "og:title",
        content: headerAttrs.reactWidgetPackDisqus.title
      },
      {
        type: "og:description",
        content: headerAttrs.reactWidgetPackDisqus.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.reactWidgetPackDisqus.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.reactWidgetPackDisqus.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.reactWidgetPackDisqus.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.reactWidgetPackDisqus.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.reactWidgetPackDisqus.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.reactWidgetPackDisqus.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.reactWidgetPackDisqus.imgUrl
      }
    ]
  },
  angularRouterApacheNginxTomcat: {
    category: "angular-router-banner",
    title: headerAttrs.angularRouterApacheNginxTomcat.title,
    metaTitle: headerAttrs.angularRouterApacheNginxTomcat.metaTitle,
    authorImage: "fab-angular",
    iconColor: "#e42839",
    authorName: "learninjava",
    authoredDate: "May 18, 2020 - Angular",
    meta: [
      {
        type: "description",
        content:
          "How to Angular Router Configuration: The actual concepts of routing along with production ready configuration on three most famous servers: Apache HTTP Server, Nginx and Tomcat. Read more."
      },
      {
        type: "og:title",
        content: headerAttrs.angularRouterApacheNginxTomcat.title
      },
      {
        type: "og:description",
        content: headerAttrs.angularRouterApacheNginxTomcat.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.angularRouterApacheNginxTomcat.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.angularRouterApacheNginxTomcat.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.angularRouterApacheNginxTomcat.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.angularRouterApacheNginxTomcat.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.angularRouterApacheNginxTomcat.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.angularRouterApacheNginxTomcat.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.angularRouterApacheNginxTomcat.imgUrl
      }
    ]
  },
  angularRouterConfigCLIApp: {
    category: "angular-router-banner",
    title: headerAttrs.angularRouterConfigCLIApp.title,
    metaTitle: headerAttrs.angularRouterConfigCLIApp.metaTitle,
    authorImage: "fab-angular",
    iconColor: "#e42839",
    authorName: "learninjava",
    authoredDate: "May 25, 2020 - Angular",
    meta: [
      {
        type: "description",
        content:
          'Creating Angular App Using Angular CLI: Configuring Routing on Angular applications is probably the easiest, all you have to do is select the "Angular Routing". Read More.'
      },
      {
        type: "og:title",
        content: headerAttrs.angularRouterConfigCLIApp.title
      },
      {
        type: "og:description",
        content: headerAttrs.angularRouterConfigCLIApp.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.angularRouterConfigCLIApp.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.angularRouterConfigCLIApp.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.angularRouterConfigCLIApp.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.angularRouterConfigCLIApp.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.angularRouterConfigCLIApp.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.angularRouterConfigCLIApp.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.angularRouterConfigCLIApp.imgUrl
      }
    ]
  },
  reactProxyApacheNginxTomcat: {
    category: "react-api-proxying-banner",
    title: headerAttrs.reactProxyApacheNginxTomcat.title,
    metaTitle: headerAttrs.reactProxyApacheNginxTomcat.metaTitle,
    authorImage: "fab-react",
    iconColor: "#00d8fb",
    authorName: "learninjava",
    authoredDate: "Jun 07, 2020 - ReactJS",
    meta: [
      {
        type: "description",
        content:
          "React Proxy Backend API Configuration: In this tutorial, We will explain you how to configure your backend APIs on both development and production environments on three most famous servers: Apache HTTP Server, Nginx and Tomcat."
      },
      {
        type: "og:title",
        content: headerAttrs.reactProxyApacheNginxTomcat.title
      },
      {
        type: "og:description",
        content: headerAttrs.reactProxyApacheNginxTomcat.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.reactProxyApacheNginxTomcat.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.reactProxyApacheNginxTomcat.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.reactProxyApacheNginxTomcat.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.reactProxyApacheNginxTomcat.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.reactProxyApacheNginxTomcat.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.reactProxyApacheNginxTomcat.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.reactProxyApacheNginxTomcat.imgUrl
      }
    ]
  },
  reactReduxConfig: {
    category: "react-redux-banner",
    title: headerAttrs.reactReduxConfig.title,
    metaTitle: headerAttrs.reactReduxConfig.metaTitle,
    authorImage: "fab-react",
    iconColor: "#00d8fb",
    authorName: "learninjava",
    authoredDate: "Jul 21, 2020 - ReactJS",
    meta: [
      {
        type: "description",
        content: headerAttrs.reactReduxConfig.desc
      },
      {
        type: "og:title",
        content: headerAttrs.reactReduxConfig.title
      },
      {
        type: "og:description",
        content: headerAttrs.reactReduxConfig.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.reactReduxConfig.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.reactReduxConfig.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.reactReduxConfig.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.reactReduxConfig.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.reactReduxConfig.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.reactReduxConfig.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.reactReduxConfig.imgUrl
      }
    ]
  },
  soapMessageToObject: {
    category: "webservices-banner",
    title: headerAttrs.soapMessageToObject.title,
    metaTitle: headerAttrs.soapMessageToObject.metaTitle,
    authorImage: "fas-cogs",
    iconColor: "#416284",
    authorName: "learninjava",
    authoredDate: "Aug 22, 2020 - Webservices",
    meta: [
      {
        type: "description",
        content:
          "This article provides one stop guide to convert SOAPMessage to Java Object with namespaces, " +
          "SOAPMessage as XML to SOAPMessage Object using Jaxb2Marshaller, " +
          "XMLStreamReader, JAXBContext and Document APIs"
      },
      {
        type: "og:title",
        content: headerAttrs.soapMessageToObject.title
      },
      {
        type: "og:description",
        content: headerAttrs.soapMessageToObject.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.soapMessageToObject.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.soapMessageToObject.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.soapMessageToObject.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.soapMessageToObject.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.soapMessageToObject.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.soapMessageToObject.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.soapMessageToObject.imgUrl
      }
    ]
  },
  reactTableWithPagination: {
    category: "react-banner",
    title: headerAttrs.reactTableWithPagination.title,
    metaTitle: headerAttrs.reactTableWithPagination.metaTitle,
    authorImage: "fab-react",
    iconColor: "#00d8fb",
    authorName: "learninjava",
    authoredDate: "Oct 21, 2020 - ReactJS",
    meta: [
      {
        type: "description",
        content: headerAttrs.reactTableWithPagination.desc
      },
      {
        type: "og:title",
        content: headerAttrs.reactTableWithPagination.title
      },
      {
        type: "og:description",
        content: headerAttrs.reactTableWithPagination.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.reactTableWithPagination.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.reactTableWithPagination.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.reactTableWithPagination.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.reactTableWithPagination.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.reactTableWithPagination.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.reactTableWithPagination.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.reactTableWithPagination.imgUrl
      }
    ]
  },
  reactLoadingSpinnerWithOverlay: {
    category: "react-banner",
    title: headerAttrs.reactLoadingSpinnerWithOverlay.title,
    metaTitle: headerAttrs.reactLoadingSpinnerWithOverlay.metaTitle,
    authorImage: "fab-react",
    iconColor: "#00d8fb",
    authorName: "learninjava",
    authoredDate: "Oct 24, 2020 - ReactJS",
    meta: [
      {
        type: "description",
        content: headerAttrs.reactLoadingSpinnerWithOverlay.desc
      },
      {
        type: "og:title",
        content: headerAttrs.reactLoadingSpinnerWithOverlay.title
      },
      {
        type: "og:description",
        content: headerAttrs.reactLoadingSpinnerWithOverlay.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.reactLoadingSpinnerWithOverlay.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.reactLoadingSpinnerWithOverlay.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.reactLoadingSpinnerWithOverlay.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.reactLoadingSpinnerWithOverlay.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.reactLoadingSpinnerWithOverlay.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.reactLoadingSpinnerWithOverlay.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.reactLoadingSpinnerWithOverlay.imgUrl
      }
    ]
  },
  reactTableWithLoadingSpinner: {
    category: "react-banner",
    title: headerAttrs.reactTableWithLoadingSpinner.title,
    metaTitle: headerAttrs.reactTableWithLoadingSpinner.metaTitle,
    authorImage: "fab-react",
    iconColor: "#00d8fb",
    authorName: "learninjava",
    authoredDate: "Nov 01, 2020 - ReactJS",
    meta: [
      {
        type: "description",
        content: headerAttrs.reactTableWithLoadingSpinner.desc
      },
      {
        type: "og:title",
        content: headerAttrs.reactTableWithLoadingSpinner.title
      },
      {
        type: "og:description",
        content: headerAttrs.reactTableWithLoadingSpinner.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.reactTableWithLoadingSpinner.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.reactTableWithLoadingSpinner.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.reactTableWithLoadingSpinner.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.reactTableWithLoadingSpinner.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.reactTableWithLoadingSpinner.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.reactTableWithLoadingSpinner.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.reactTableWithLoadingSpinner.imgUrl
      }
    ]
  },
  webClientCRUD: {
    category: "webservices-banner",
    title: headerAttrs.webClientCRUD.title,
    metaTitle: headerAttrs.webClientCRUD.metaTitle,
    authorImage: "fas-cogs",
    iconColor: "#416284",
    authorName: "learninjava",
    authoredDate: "Dec 05, 2020 - Webservices",
    meta: [
      {
        type: "description",
        content:
          "Spring WebClient - GET, PUT, POST, DELETE examples: Learn how to " +
          "create, read, update and delete using WebClient, an alternative to " +
          "RestTemplate, examples using GET, POST, PUT and DELETE http methods."
      },
      {
        type: "og:title",
        content: headerAttrs.webClientCRUD.title
      },
      {
        type: "og:description",
        content: headerAttrs.webClientCRUD.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.webClientCRUD.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.webClientCRUD.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.webClientCRUD.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.webClientCRUD.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.webClientCRUD.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.webClientCRUD.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.webClientCRUD.imgUrl
      }
    ]
  },
  webClientTesting: {
    category: "webservices-banner",
    title: headerAttrs.webClientTesting.title,
    metaTitle: headerAttrs.webClientTesting.metaTitle,
    authorImage: "fas-cogs",
    iconColor: "#416284",
    authorName: "learninjava",
    authoredDate: "Dec 05, 2020 - Webservices",
    meta: [
      {
        type: "description",
        content:
          "Mocking a WebClient in Spring: Learn how to test WebClient, " +
          "examples include testing using Mockito, MockWebServer, WebTestClient " +
          "and WireMockServer."
      },
      {
        type: "og:title",
        content: headerAttrs.webClientTesting.title
      },
      {
        type: "og:description",
        content: headerAttrs.webClientTesting.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.webClientTesting.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.webClientTesting.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.webClientTesting.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.webClientTesting.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.webClientTesting.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.webClientTesting.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.webClientTesting.imgUrl
      }
    ]
  },
  reactTableWithRecordCount: {
    category: "react-banner",
    title: headerAttrs.reactTableWithRecordCount.title,
    metaTitle: headerAttrs.reactTableWithRecordCount.metaTitle,
    authorImage: "fab-react",
    iconColor: "#00d8fb",
    authorName: "learninjava",
    authoredDate: "Dec 21, 2020 - ReactJS",
    meta: [
      {
        type: "description",
        content: headerAttrs.reactTableWithRecordCount.desc
      },
      {
        type: "og:title",
        content: headerAttrs.reactTableWithRecordCount.title
      },
      {
        type: "og:description",
        content: headerAttrs.reactTableWithRecordCount.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.reactTableWithRecordCount.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.reactTableWithRecordCount.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.reactTableWithRecordCount.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.reactTableWithRecordCount.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.reactTableWithRecordCount.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.reactTableWithRecordCount.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.reactTableWithRecordCount.imgUrl
      }
    ]
  },
  reactTableWithNestedSubTable: {
    category: "react-banner",
    title: headerAttrs.reactTableWithNestedSubTable.title,
    metaTitle: headerAttrs.reactTableWithNestedSubTable.metaTitle,
    authorImage: "fab-react",
    iconColor: "#00d8fb",
    authorName: "learninjava",
    authoredDate: "Jan 05, 2021 - ReactJS",
    meta: [
      {
        type: "description",
        content: headerAttrs.reactTableWithNestedSubTable.desc
      },
      {
        type: "og:title",
        content: headerAttrs.reactTableWithNestedSubTable.title
      },
      {
        type: "og:description",
        content: headerAttrs.reactTableWithNestedSubTable.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.reactTableWithNestedSubTable.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.reactTableWithNestedSubTable.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.reactTableWithNestedSubTable.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.reactTableWithNestedSubTable.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.reactTableWithNestedSubTable.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.reactTableWithNestedSubTable.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.reactTableWithNestedSubTable.imgUrl
      }
    ]
  },
  easyGuideToSSL: {
    category: "java-banner",
    title: headerAttrs.easyGuideToSSL.title,
    metaTitle: headerAttrs.easyGuideToSSL.metaTitle,
    authorImage: "fab-java",
    iconColor: "#086eb6",
    authorName: "learninjava",
    authoredDate: "Feb 20, 2021 - Java",
    meta: [
      {
        type: "description",
        content: headerAttrs.easyGuideToSSL.desc
      },
      {
        type: "og:title",
        content: headerAttrs.easyGuideToSSL.title
      },
      {
        type: "og:description",
        content: headerAttrs.easyGuideToSSL.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.easyGuideToSSL.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.easyGuideToSSL.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.easyGuideToSSL.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.easyGuideToSSL.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.easyGuideToSSL.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.easyGuideToSSL.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.easyGuideToSSL.imgUrl
      }
    ]
  },
  pkcsAndPemToJks: {
    category: "java-banner",
    title: headerAttrs.pkcsAndPemToJks.title,
    metaTitle: headerAttrs.pkcsAndPemToJks.metaTitle,
    authorImage: "fab-java",
    iconColor: "#086eb6",
    authorName: "learninjava",
    authoredDate: "Feb 23, 2021 - Java",
    meta: [
      {
        type: "description",
        content: headerAttrs.pkcsAndPemToJks.desc
      },
      {
        type: "og:title",
        content: headerAttrs.pkcsAndPemToJks.title
      },
      {
        type: "og:description",
        content: headerAttrs.pkcsAndPemToJks.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.pkcsAndPemToJks.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.pkcsAndPemToJks.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.pkcsAndPemToJks.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.pkcsAndPemToJks.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.pkcsAndPemToJks.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.pkcsAndPemToJks.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.pkcsAndPemToJks.imgUrl
      }
    ]
  },
  tlsMutualAuth: {
    category: "java-banner",
    title: headerAttrs.tlsMutualAuth.title,
    metaTitle: headerAttrs.tlsMutualAuth.metaTitle,
    authorImage: "fab-java",
    iconColor: "#086eb6",
    authorName: "learninjava",
    authoredDate: "Feb 27, 2021 - Java",
    meta: [
      {
        type: "description",
        content: headerAttrs.tlsMutualAuth.desc
      },
      {
        type: "og:title",
        content: headerAttrs.tlsMutualAuth.title
      },
      {
        type: "og:description",
        content: headerAttrs.tlsMutualAuth.desc
      },
      {
        type: "og:image:secure_url",
        content: headerAttrs.tlsMutualAuth.imgUrl
      },
      {
        type: "og:image",
        content: headerAttrs.tlsMutualAuth.imgUrl
      },
      {
        type: "og:url",
        content: headerAttrs.tlsMutualAuth.url + "/"
      },
      {
        type: "canonical",
        content: headerAttrs.tlsMutualAuth.url
      },
      {
        type: "twitter:title",
        content: headerAttrs.tlsMutualAuth.title
      },
      {
        type: "twitter:description",
        content: headerAttrs.tlsMutualAuth.desc
      },
      {
        type: "twitter:image",
        content: headerAttrs.tlsMutualAuth.imgUrl
      }
    ]
  }
};

export default allHeaders;
