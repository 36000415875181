import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import RenderIcon from "components/CustomWidgets/RenderIcon";

class TechBox extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { icon, iconType, color, header, desc, link } = this.props;
    return (
      <div className="col-md-3">
        <div className="box">
          <div className="box-gray aligncenter">
            <Link to={link}>
              <RenderIcon name={icon} type={iconType} color={color} size={44} />
            </Link>
            <Link className="dropdown-item" to={link}>
              <h4>{header}</h4>
            </Link>
            <p className="box-desc">{desc}</p>
          </div>
          <div className="box-bottom">
            <Link to={link}>Read more</Link>
          </div>
        </div>
      </div>
    );
  }
}

TechBox.defaultProps = {
  iconType: "icon"
};

TechBox.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.string,
  header: PropTypes.string,
  desc: PropTypes.string,
  link: PropTypes.string,
  iconType: PropTypes.string
};

export default TechBox;
