import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["userThreadDaemonThread"],
  ...allHashTags,
  channel: "5"
};

const data = [
  {
    type: "subheading",
    name: "user-thread-and-daemon-thread",
    title: "User and Daemon ?"
  },
  {
    type: "text",
    content:
      "A User thread is a thread that is created by the User i.e, the application " +
      "where as a Daemon thead is a thread that is created to serve the user thread. " +
      "A Daemon thread is usually made to run in the background continuously. User " +
      "threads are terminated once the <code>run()</code> method completes. Daemon threads are " +
      "automatically terminated by the JVM when only threads running are the daemon threads." +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "1. Garbage Collector is a good example of a daemon thread" +
          "<div class='lineBreakSmall' /> " +
          "2. Any thread that listens to the incoming requests can be made daemon thread" +
          "<div class='lineBreakSmall' /> " +
          "3. Services sending the inputs to a dashboard or monitoring service can be created as a daemon thread"
      }
    ]
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "text",
    content: "Daemon thread example is shown below :"
  },
  {
    type: "gist",
    content: "412fe963587684d244ca5f4fed909a0a"
  },
  {
    type: "output",
    content: "\rExiting main thread...\rIn run method of daemon thead...\r\n"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "1. The JVM exits if only threads running are the daemon threads i.e, the JVM does not wait for the daemon threads unless join() method is called on it" +
          "<div class='lineBreakSmall' /> " +
          "2. The JVM does not call the finally methods of daemon threads" +
          "<div class='lineBreakSmall' /> " +
          "3. Use setDaemon(true) only before calling start() method"
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Observe that since the thread we created is a daemon thread, the JVM does not wait for the thread to complete and hence it prints the statement in thread only once and exits. Also, the JVM ignore the statement in finally block. As discussed, JVM does not call the final methods of daemon threads."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<b>Note</b>: The above output is NOT guaranteed to be same as shown. Sometimes, the JVM may exit without even printing the statement in while loop."
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "<div class='lineBreakSmall' /> " +
          "The output of the below example shows that the JVM exits without waiting for the while loop to complete. Also observe that the statement in finally is not displayed (rule 2). Uncomment the join() statement and re-run the example. Now you will see that the JVM is waiting for the daemon thread to complete (rule 1)."
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreakSmall' /> " +
      "User & Daemon thread example is shown below :"
  },
  {
    type: "gist",
    content: "48e790523c6116ad73636543ba929004"
  },
  {
    type: "output",
    content:
      "\rIn run method of daemon thead..." +
      "\rIn run method of user thead..." +
      "\rExiting main thread..." +
      "\rIn finally method of daemon thread..." +
      "\rIn run method of daemon thead..." +
      "\rIn finally method of daemon thread..." +
      "\rIn run method of daemon thead..." +
      "\rIn finally method of user thread..." +
      "\rIn finally method of daemon thread...\r\n"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "<div class='lineBreakSmall' /> " +
          "From the output it is clear that the JVM waits for the user thread to complete. During this time, it processes the daemon thread and also the finally method of daemon thread. Once the 3 second sleep of user thread is over, the loop breaks and the user thread completes. JVM then observes that the only thread running now is the daemon thread. JVM then exits immediately."
      }
    ]
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/thread-states/",
    previousText: "Thread States - Life cycle of a Thread",
    nextURL: "/why-equals-and-hashcode-override/",
    nextText: "Equals and HashCode Override"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/user-and-daemon-threads.zip"
  }
];

class UserThreadDaemonThread extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default UserThreadDaemonThread;
