import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";

const header = {
  ...allHeaders["restfulWebserviceUsingJersey"],
  hashTags: ["java", "springboot", "react", "webservices", "spring"],
  hashTagColors: ["blue", "warning", "info", "danger", "success"],
  channel: "11"
};

const data = [
  {
    type: "subheading",
    name: "restful-webservice-using-jersey",
    title: "WebService"
  },
  {
    type: "text",
    content:
      "Any service which follows the REST (REpresentational State Transfer) architectural pattern is said to be restful. " +
      "Let us look at an example on how to create a simple restful webservice using Jersey and Spring. The following software has been used for this tutorial : " +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "1. Apache Maven 3.0.4<br /> " +
      "2. Spring 3.2.6<br /> " +
      "3. Jersey 1.19<br /> " +
      "4. Jetty 6.1.10/Tomcat 7 " +
      "<div class='lineBreak' /> " +
      "First, we need to create a basic Jersey web project structure." +
      "<div class='lineBreakSmall' /> " +
      "Create a basic maven web project structure similar to below (Refer Download Source) : " +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<img src='https://github.com/learninjavagithub/assets/raw/master/jersey-webservice-project-structure.jpg' alt='jersey-webservice-project-structure' />"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "You MUST use the Test case file name pattern as below because the maven surefire plugin expects the test file " +
          "names in the following patterns : " +
          "<div class='lineBreakSmall' /> " +
          "**/Test*.java<br /> " +
          "**/*Test.java<br /> " +
          "**/*TestCase.java<br /> "
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "For your convenience, we have provided configurations for running the webservice on both Jetty and Tomcat servers. " +
      "<div class='lineBreak' /> " +
      "First, let us take a look at tomcat configuration. Later we will see about jetty configuration. There is a cool feature in jetty that attracts " +
      "many developers. we will see what is it later in this tutorial."
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "using-tomcat",
    title: "Using Tomcat :"
  },
  {
    type: "text",
    content: "<h4>1. Change the default pom.xml as below :</h4>"
  },
  {
    type: "gist",
    content: "251c08c4790c40991e4c0c932e0c81d7"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "You will notice that there are 4 important plugins in the pom.xml out of which 2 are optional. We will see each of them in detail : " +
      "<div class='lineBreakSmall' /> " +
      "<b>maven-surefire-plugin</b> - [Optional] This plugin is added to specify the service.url in the pom.xml itself. We will see its usage while running the web service client." +
      "<div class='lineBreakSmall' /> " +
      "<b>tomcat7-maven-plugin</b> - This is the tomcat plugin where we will deploy our angry world webservice." +
      "<div class='lineBreakSmall' /> " +
      "<b>maven-compiler-plugin</b> - This plugin specifies the Java compiler source and target versions." +
      "<div class='lineBreakSmall' /> " +
      "<b>maven-eclipse-plugin</b> - [Optional] This plugin is used to generate the .classpath and .project files which enables us to use this project in eclipse." +
      "<div class='lineBreakSmall' /> " +
      "The other important sections includes dependencies and properties which are used to specify the jar file dependencies and properties respectively. " +
      "We have commented out a certain section of dependencies. We will see that when we create a client, " +
      "<MyLink class='homelink' href='/restful-client-using-jersey' target='_blank'><span>Restful client using Jersey</span></MyLink>" +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content: "<h4>2. HelloAngryWorld.java :</h4>"
  },
  {
    type: "gist",
    content: "aab86b4a33f0145498ba3650a121740f"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "This is the actual webservice source file, so lets take a look at it." +
      "<div class='lineBreakSmall' /> " +
      "This webservice exposes two methods one of type plain text and other of type JsonBean. These methods uses some important annotations: " +
      "<div class='lineBreakSmall' /> " +
      "<b>@Path</b> - annotation at class level specifies the context path to access this webservice." +
      "<div class='lineBreakSmall' /> " +
      "<b>@Path</b> - annotation at method level specifies the path to access the actual method in the webservice." +
      "<div class='lineBreakSmall' /> " +
      "<b>@PathParam</b> - annotation is used to accept input from the request URL path specified by @Path." +
      "<div class='lineBreakSmall' /> " +
      "<b>@Produces</b> - annotation is used to specify the return type of the method." +
      "<div class='lineBreakSmall' /> " +
      "<b>@Consumes</b> - annotation is used to specify the return type accepted as input to a method." +
      "<div class='lineBreakSmall' /> " +
      "For a complete listing of annotations refer : <a class='homelink' href='http://java-hub.blogspot.in/2015/01/jax-rs-annotations.html' target='_blank'>jax-rs-annotations</a>" +
      "<div class='lineBreak' /> "
  },
  {
    type: "text",
    content: "<h4>3. JsonBean.java :</h4>"
  },
  {
    type: "gist",
    content: "7c425906b6cd84a65e498204ae3bc8ea"
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h4>4. beans.xml :</h4>"
  },
  {
    type: "gist",
    content: "5e07bd0c4f40cac0e72b01a59f36cd3a"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "<code>PreferencesPlaceholderConfigurer</code> and <code>ServletContextPropertyPlaceholderConfigurer</code> are used to handle properties files. See references section at the bottom of this tutorial for more information." +
      "<div class='lineBreakSmall' />" +
      "The most significant part of beans.xml is the bean definition of the webservice we are going to expose."
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h4>5. web.xml :</h4>"
  },
  {
    type: "gist",
    content: "23adb0b54e4ec0f6b905aa10438ae386"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "The configurations in web.xml are straighforward. SpringServlet is the actual servlet that reads the xml configurations and makes the service beans as webservices and handles the requests and responses to the exposed services." +
      "<div class='lineBreakSmall' />"
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "Observe that SpringServlet is spring enabled servlet for exposing webservices using Jersey. If we dont want to use Jersey " +
          "then you can use ServletContainer instead (disabled in web.xml). "
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "ContextLoaderListener is the ROOT web application context that is used by spring." +
      "<div class='lineBreakSmall' />" +
      "<code style='white-space: normal'>com.sun.jersey.config.property.packages</code> - " +
      "specifies the package name for the webservice artifacts" +
      "<div class='lineBreakSmall' />" +
      "<code style='white-space: normal'>POJOMappingFeature</code> - " +
      "is responsible for mapping java objects to JSON." +
      "<div class='lineBreakSmall' />" +
      "Once the above files are ready, type the below command to start the tomcat container :" +
      "<div class='lineBreakSmall' />" +
      "<code style='white-space: normal'>mvn clean package -DskipTests=true tomcat7:run</code>" +
      "<div class='lineBreakSmall' />" +
      "This command cleans the target directory, compiles, builds war and deploys it to tomcat. Notice that we used skipTests = true switch. This is to make sure that the test case are not run before the server starts." +
      "<div class='lineBreakSmall' />" +
      "Navigate to the URL :" +
      "<div class='lineBreakSmall' />" +
      "<i>http://localhost:9090/jaxrs-service/helloAngryWorld/echo/tweet</i>"
  },
  {
    type: "output",
    content: "\rtweet\r\n"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "This is an echo service so whatever you input in the URL should be echoed to your browser."
  },
  {
    type: "googleAd",
    position: "middle4"
  },
  {
    type: "subheading",
    name: "using-jetty",
    title: "Using Jetty :"
  },
  {
    type: "text",
    content: "<h4>1. pom.xml :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "The pom.xml is pretty much similar to the one we have used for tomcat configuration except that the tomcat plugin is replaced with the jetty equivalent. Also the port number is changed 8080 just to distinguish between tomcat and jetty."
  },
  {
    type: "gist",
    content: "5725ff5ea7353f3a4fdfa2352671b904"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "All other steps are same as above." +
      "<div class='lineBreakSmall' />" +
      "To start the jetty container and the webservice use the below command :" +
      "<div class='lineBreakSmall' />" +
      "<code style='white-space: normal'>mvn clean package -DskipTests=true jetty:run</code>" +
      "<div class='lineBreakSmall' />" +
      "Navigate to the URL :" +
      "<div class='lineBreakSmall' />" +
      "<i>http://localhost:8080/jaxrs-service/helloAngryWorld/echo/tweet</i>"
  },
  {
    type: "output",
    content: "\rtweet\r\n"
  },
  {
    type: "subheading",
    name: "advantages-of-using-jetty",
    title: "Advantage of using Jetty - Hot code deployment :"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "By default, Jetty scans the following directories :" +
      "<div class='lineBreakSmall' />" +
      "<b>target/classes</b> - for changes to Java sources and" +
      "<div class='lineBreakSmall' />" +
      "<b>src/main/webapp</b> - for changes to web sources." +
      "<div class='lineBreakSmall' />" +
      "If any source file is modified, the changes are autodeployed to the jetty container without manually restarting for the changes to take effect." +
      "<div class='lineBreakSmall' />" +
      "To test this hot code deployment, try to change any source file. We have changed the Path param of ping method from echo to ping. Now, recompiled the source using <code>mvn compile</code> command." +
      "<div class='lineBreakSmall' />" +
      "The moment the source file is compiled, notice the server console. You will observe that jetty hot deploys the code automatically and you can access the changes via browser instantly." +
      "<div class='lineBreakSmall' />" +
      "Navigate to the URL :" +
      "<div class='lineBreakSmall' />" +
      "<i>http://localhost:8080/jaxrs-service/helloAngryWorld/ping/tweet</i>"
  },
  {
    type: "output",
    content: "\rtweet\r\n"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/restful-client-using-jersey/",
    previousText: "JAX-RS Restful Client using Jersey",
    nextURL: "/jaxws-soap-webservice-using-cxf/",
    nextText: "JAX-WS SOAP WebService using CXF"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/jerseyjaxrsws.zip"
  }
];

class RestfulWebserviceUsingJersey extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default RestfulWebserviceUsingJersey;
