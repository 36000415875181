import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["equalsAndHashCodeOverride"],
  ...allHashTags,
  channel: "7"
};

const data = [
  {
    type: "subheading",
    name: "equals-and-hashCode-override",
    title: "Why you should override ?"
  },
  {
    type: "text",
    content:
      "You might have seen a hundred articles on the internet on why you should override the hashcode method when you override the equals. " +
      "But we are sure you might not be convinced or confused as to why you should be doing that. " +
      "In the below article, we will try to explain the same in simpler terms and with our very famous angry birds example. " +
      "<div class='lineBreak' /> "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The reason why you should override hashcode whenever you override equals is that you need a way to create the same object you want to find. " +
      "This is particularly useful when you are dealing with collections. " +
      "<div class='lineBreak' /> " +
      "Finding an object is a two step process :" +
      "<div class='lineBreak'></div> " +
      "<li>Find the bucket holding the object</li>" +
      "<li>Compare the objects in the bucket with the object we are trying to find</li>" +
      "<div class='lineBreak' /> " +
      "Overriding hashCode() method provides a way to find the actual bucket in which the object is present. Overriding equals() method " +
      "provides a way to compare and see if the object we are finding is same or not."
  },
  {
    type: "info",
    content: [
      {
        type: "text",
        content:
          "<li>You need a way to create the same object you want to find</li>" +
          "<li>hashCode() helps us to find the correct bucket</li>" +
          "<li>equals() helps us to compare the objects in the bucket</li>"
      }
    ]
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "First, let us take a look the below example. Here, we are creating 3 AngryBird " +
      "objects with different colors and adding it to a HashSet. Now, our goal is to " +
      "find RED coloured bird. We will not override the hashCode() method as of now. " +
      "Let us run the example and see the output,"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "equals-without-overriding-hashcode",
    title: "EqualsAndHashCode.java : - Without overriding hashCode"
  },
  {
    type: "gist",
    content: "19553e05d000fa7473216e6852da1b0d"
  },
  {
    type: "output",
    content:
      "HashCode of RED bird to find : 1\r\n" +
      "HashCodes of birds in buckets..\r" +
      "Color : BLACK HashCode : 2018699554\r\n" +
      "Color : RED HashCode : 366712642\r\n" +
      "Color : BLUE HashCode : 1829164700\r\n" +
      "Is RED angry bird present in the buckets ? : false\r\n"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "From the output, if we do not override the hashCode() method, the " +
      "RED bird to find has a different hashCode than RED bird in the HashSet. " +
      "This means there is no RED angry bird in the HashSet which is wrong. " +
      "Now, let us override the hashCode() and see the output. Below is the updated example," +
      "<div class='lineBreak' /> "
  },
  {
    type: "subheading",
    name: "equals-with-overriding-zero-hashcode",
    title: "EqualsAndHashCode.java : - With overriden hashCode as zero"
  },
  {
    type: "gist",
    content: "0e4159949f249666b081c28daafe0330"
  },
  {
    type: "output",
    content:
      "HashCode of RED bird to find : 0\r\n" +
      "HashCodes of birds in buckets..\r" +
      "Color : RED HashCode : 0\r\n" +
      "Color : BLUE HashCode : 0\r\n" +
      "Color : BLACK HashCode : 0\r\n" +
      "Is RED angry bird present in the buckets ? : true\r\n"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Aah! After overriding the hashCode(), we now see that the hashcodes " +
      "of RED angry birds matches. Also notice that now we can find the object " +
      "in the HashSet." +
      "<div class='lineBreak' /> " +
      "Are we done ? Not yet, notice that we now have the same hashcode for all " +
      "the angry birds. This is not a violation or does cause any harm. If you dont " +
      "know how to override hashCode(), simply return zero as shown." +
      "<div class='lineBreak' /> " +
      "However, an efficient hashCode algorithm must distribute the objects in the " +
      "buckets evenly. To understand this, we will analyse using a diagram but before " +
      "that let us override the hashCode with a better algorithm. Here is how we can do one,"
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "subheading",
    name: "equals-with-overriding-efficient-hashcode",
    title: "EqualsAndHashCode.java : - With overriden and efficient hashCode"
  },
  {
    type: "gist",
    content: "fa205fe7b0b1bc1c5b3ce820cfe798ed"
  },
  {
    type: "output",
    content:
      "HashCode of RED bird to find : 1\r\n" +
      "HashCodes of birds in buckets..\r" +
      "Color : RED HashCode : 1\r\n" +
      "Color : BLUE HashCode : 2\r\n" +
      "Color : BLACK HashCode : 3\r\n" +
      "Is RED angry bird present in the buckets ? : true\r\n"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Now since the hashCode returns the size of the bird rather than just a zero, observe that " +
      "each bird has a different hash code according to their size. What is the advantage with this ? " +
      "<div class='lineBreak' /> " +
      "Let us look at the below diagram and see what happened on the heap in each of the above cases. "
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/equals-and-hashcode.jpg",
    height: "504px",
    width: "587px"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The number in the square brackets is the hashcode. The bird outside the buckets is the one we want to create " +
      "as a key and match it with the one in the bucket. " +
      "<div class='lineBreak' /> " +
      "When we did not override the hashCode() method, the birds are distributed into separate buckets, but " +
      "there is no way to find a particular bird as the hashCodes were not equal. " +
      "When the hashCode was returning zeros, all the birds were squeezed into a single bucket. " +
      "When the hashCode is overriden with efficient algorithm, the birds were distributed evenly and we could also " +
      "find the bird we wanted." +
      "<div class='lineBreak' /> " +
      "Hope, after reading through this article, your search for the hashCode and equals ends... :)"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/user-thread-and-daemon-thread/",
    previousText: "User and Daemon Threads",
    nextURL: "/thread-states/",
    nextText: "Thread States - Life cycle of a Thread"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/equals-and-hashcode.zip"
  }
];

class EqualsAndHashCodeOverride extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default EqualsAndHashCodeOverride;
