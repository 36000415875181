import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["reactReduxConfig"],
  ...allHashTags,
  channel: "19"
};

const data = [
  {
    type: "subheading",
    name: "react-redux",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "The internet is full of redux articles and also some of them explains how to configure react with redux. " +
      "Ever tried to follow them and failed ? Even after hours of effort trying to configure, have to resort to other articles ? then you " +
      "are in the right place. We know exactly how you are feeling. This is why we wanted to make your life easy with this post. " +
      "We hope your search ends after reading through this article. So, relax and follow along." +
      "<div class='lineBreak' /> " +
      "Before we get our hands dirty, lets quickly understand what is redux and thunk."
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "what-is-redux",
    title: "What is Redux ?"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Redux is a predictable state container for Javascript apps. This is the official description. " +
      "Wow!! So easy to understand right ? Alright, we know what you are thinking. In layman's terms, " +
      "its a single place to store all your state. Remember, you have a state object in every React class. " +
      "If you are using plain React without redux, you will be storing the state related to a particular class in its state object. " +
      "If you have multiple classes then every class holds its own state. " +
      "<div class='lineBreak' /> " +
      "Consider this as a local state object, as this is local to the class. " +
      "What if there is a state which is common to all classes and every react class can acccess it. Let's name it as Global state object. " +
      "This single global state management system is called Redux. That's the easy way to understand redux."
  },
  {
    type: "subheading",
    name: "what-is-thunk-middleware",
    title: "What is Thunk Middleware ?"
  },
  {
    type: "text",
    content:
      "In Redux, every action is just a plain object and does not perform any action even though its called an action. " +
      "Thunk extends the action object's functionality to perform an action like calling an API or a method, to log something etc. " +
      "It's called a middleware because it sits in the middle of Redux and React and scans every action, if the action is a function, it just calls it. " +
      "Don't think much about thunk. It is just a pattern."
  },
  {
    type: "subheading",
    name: "configure-react-and-redux",
    title: "Confgure React and Redux"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "If you are struggling to configure React Router in your application, refer to this easy step by step tutorial. " +
      "<MyLink class='homelink' href='/create-react-app-react-router' target='_blank'><span>Create React App - React Router Setup</span></MyLink>. " +
      "We will use the same application configured in this tutorial and extended it to configure Redux." +
      "<div class='lineBreakSmall' /> " +
      "Go ahead and get your self familiarized with the above tutorial's source code. We will get redux running on this application with " +
      "just a couple of changes."
  },
  {
    type: "text",
    content: "<h4>1. Add action - UserAction.js :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "This action will hold all the redux actions related to the user screen. You can also move " +
      "all the action types like GET_USER, ADD_USER etc into a separate file called UserActionType.js and include it into the action file."
  },
  {
    type: "gist",
    content: "8beca1970954ebdbfefdcf3763d78ed6"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>2. Add reducer - RootReducer.js :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "This is the root reducer. This combines all the meta reducers, which means reducers grouped by some category. " +
      "For example, if you have a list of links on your left side bar then all reducers related to those links goes into the left side bar reducer " +
      "and the left side bar reducer will be in root reducer." +
      "<div class='lineBreakSmall' /> " +
      "In our case, we have all the actions in our main page, so we named it as MainContentReducer." +
      "Ideally, the easy way to do this is categorize by layout, left sidebar, right sidebar, main content, header, footer etc."
  },
  {
    type: "gist",
    content: "1d2a83bd69cd2261efaccee7d77d0211"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>3. Add reducer - MainContentReducer.js :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "As explained above, this is our main content reducer."
  },
  {
    type: "gist",
    content: "870d75a845db34a0e7fdfff296b8b60c"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>4. Add reducer - UserReducer.js :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "This is our actual reducer. If you look at the UserReducer function, there are different " +
      "actions for each action type. The inital state object has two entries with default values."
  },
  {
    type: "gist",
    content: "2488526d35b020a854a0fa866c9cb73e"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h4>5. Add store - Store.js :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "This is actual store that will hold all the state in our application. " +
      "Observe that Thunk is also configured as a middle ware. " +
      "Other important configuration here is the Redux Development Tools configuration. " +
      "<div class='lineBreakSmall' /> " +
      "Want to know what Redux DevTools does ? Well, it will show how redux is working and storing in a visual interface. " +
      "Dont worry, we will walk you through it as well. Hold on until then."
  },
  {
    type: "gist",
    content: "9c831b851c4f7223a0c17220c09d2520"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h4>6. Modify index.js to include the redux Store :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Modify the already existing index.js to include the store using <code>Provider</code> tag of redux. "
  },
  {
    type: "gist",
    content: "1901e4b354584037cac62592fc6766ef"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Thats all you need for the redux configuration. We will now run and validate that the redux configuration is working fine. " +
      "You can find source of the working application at the end. Check &quot;Download Source&quot; section."
  },
  {
    type: "mediaAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "react-redux-config-run",
    title: "Run the Application:"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Run the application using <code>npm start</code> and " +
      "Navigate to, <code>http://localhost:3000/react-redux-config</code> in your browser. " +
      "Click the User link and you should see something similar to the below screenshot. "
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-redux-config1.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The firstname and lastname from the store are retrieved and displayed in the user screen. " +
      "Wait, how can we be sure that they are coming from the store, to check that we need to use Redux DevTools Chrome Extension. " +
      "<div class='lineBreakSmall' /> " +
      "Remember we configured Redux Dev Tools in Store.js ? This is exact reason why we may want to use this tool. " +
      "This extension will help us view, debug and also replay our actions. " +
      "Click on the highlighted extension and you will see the Redux DevTools window as shown in the screenshot." +
      "<div class='lineBreakSmall' /> " +
      "The tool window provides different kinds of views like Tree, Chart and Raw. You can also select the Action, State, Diff etc."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-redux-config2.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "By default, the first action that is called when the application is loaded is <code>@@INIT</code>. " +
      "Try modifying the values in the text fields and you will see that the <code>ADD_USER</code> action will be called. " +
      "Similarly, when you hit the Clear button, the <code>DEL_USER</code> action will be called. " +
      "<div class='lineBreakSmall' /> " +
      "You can play around the various functionalities in this window. These are very useful while development when you want to debug your " +
      "workflows to see how the state changes are happening."
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/react-router-apache-nginx-tomcat/",
    previousText:
      "React Router Configuration - Apache Http Server, Nginx, Tomcat",
    nextURL: "/react-widgetpack-free-disqus-alternative/",
    nextText:
      "React WidgetPack Comments - A Free Disqus alternative - Step by Step"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/react-redux-config.zip"
  }
];

class ReactReduxConfig extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default ReactReduxConfig;
