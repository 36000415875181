import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["reactTableWithNestedSubTable"],
  ...allHashTags,
  channel: "27"
};

const data = [
  {
    type: "toc",
    title: "React Table Series - Table of Contents",
    content:
      "<div class='lineBreak' /> " +
      "<h4><b>Part 1 : </b> <MyLink class='homelink' href='/how-to-create-react-table-with-pagination-and-sorting' target='_blank'>" +
      "React Table - How to create a simple react table with pagination and sorting</MyLink></h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 2 : </b> <MyLink class='homelink' href='/react-table-with-loading-spinner' target='_blank'>" +
      "React Table - How to add Loading Spinner</MyLink></h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 3 : </b> <MyLink class='homelink' href='/react-table-with-record-count' target='_blank'>" +
      "React Table - How to add a Record Count</MyLink></h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 4 : </b> React Table - How to create a nested sub table - [This article]</h4>"
  },
  {
    type: "subheading",
    name: "react-table-with-nested-subtable",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "In our previous article, we learned how to add a record count bar to a React Table. " +
      "In this continuation article, we will show you how to create a nested sub table in the table that we created here, " +
      "<MyLink class='homelink' href='/react-table-with-record-count' target='_blank'>" +
      "React Table - How to add a Record Count</MyLink>" +
      "<div class='lineBreakSmall' /> " +
      "Go to the <code>Download Source</code> section of the above tutorial and once you get access to the source, follow the below steps, " +
      "as we will be modifying some of the files in that app."
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "setup-proxy-configuration",
    title: "Setup proxy configuration"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Open the file <code>setupProxy.js</code> and add the below proxy configuration. You can also" +
      "modify the existing configuration to use a regular expression if you want all the URLs to be redirected to the same proxy."
  },
  {
    type: "gist",
    content: "d3e7f25f43af266225051039872c68d0"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "add-nested-table-as-sub-component",
    title: "Add the nested table as sub component"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Now, open Dashboard.js and modify the code as below. This component " +
      "just fetches the data and displays as a table."
  },
  {
    type: "gist",
    content: "2d6b153cc34a88051bbf870311867830"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The above code is exactly same as the original code in the previous article, " +
      "except for the numbered lines. " +
      "Let's see what are the differences at the highlighted line numbers:" +
      "<div class='lineBreakSmall' /> " +
      "1. First we imported the nested child table component - <code>Posts</code>" +
      "<div class='lineBreakSmall' /> " +
      "2. Added the nested child table as a SubComponent to the React Table"
  },
  {
    type: "subheading",
    name: "create-nested-child-table-component",
    title: "Create nested child table component"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Create a file named <code>Posts.js</code> and add the code as below. This will create the child table component that " +
      "fetches the data from API when the expand button is clicked."
  },
  {
    type: "gist",
    content: "c193e926a6fd260b877b523c8cded45b"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Thats all, Now, Open the browser and test the code, you should see a table with data as shown below. " +
      "Click on the expand button and observe that a nested child table is shown. Clicking again on the button will " +
      "collapse the child table."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-table-with-nested-subtable.jpg"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/react-table-with-record-count/",
    previousText: "React Table - How to add a Record Count",
    nextURL: "/react-router-apache-nginx-tomcat/",
    nextText: "React Router Configuration - Apache Http Server, Nginx, Tomcat"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/react-table-with-nested-sub-table.zip"
  }
];

class ReactTableWithNestedSubTable extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default ReactTableWithNestedSubTable;
