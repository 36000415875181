import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["reactTableWithPagination"],
  ...allHashTags,
  channel: "21"
};

const data = [
  {
    type: "toc",
    title: "React Table Series - Table of Contents",
    content:
      "<div class='lineBreak' /> " +
      "<h4><b>Part 1 : </b> React Table - How to create a simple react table with pagination and sorting - [This article]</h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 2 : </b> <MyLink class='homelink' href='/react-table-with-loading-spinner' target='_blank'>" +
      "React Table - How to add Loading Spinner</MyLink></h4>" +
      "<h4><b>Part 3 : </b> <MyLink class='homelink' href='/react-table-with-record-count' target='_blank'>" +
      "React Table - How to add a Record Count</MyLink></h4>" +
      "<h4><b>Part 4 : </b> <MyLink class='homelink' href='/react-table-with-nested-subtable' target='_blank'>" +
      "React Table - How to create a nested sub table</MyLink></h4>"
  },
  {
    type: "subheading",
    name: "react-table-with-pagination",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "Everyone wants the data at somepoint to be shown in the form of a table. " +
      "Let us look at how to create a simple table in react with pagination " +
      "and sorting the data." +
      "<div class='lineBreakSmall' /> " +
      "To save time, we will use the app that we created before to retrieve data from an API. " +
      "We will use this data to show in our table. If you want to know how to retrieve data " +
      "from an API in react, read this tutorial - <MyLink class='homelink' href='/react-proxying-api-backend-apache-nginx-tomcat' target='_blank'>" +
      "<span>React Proxy Backend API configuration</span></MyLink>" +
      "<div class='lineBreakSmall' /> " +
      "Go to the <code>Download Source</code> section of the above tutorial and once you get access to the source, follow the below steps, " +
      "as we will be modifying some of the files in that app."
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "install-react-table",
    title: "Install react-table"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Open command prompt, navigate to the application root folder and type," +
      "<div class='lineBreak' /> " +
      "<code>npm install react-table</code>" +
      "<div class='lineBreak' /> " +
      "That is all !! Yes, we dont need any other packages."
  },
  {
    type: "subheading",
    name: "add-react-table",
    title: "Add react-table component"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Now, open Dashboard.js and modify the code as below. This component " +
      "will just fetches the data and displays as a table."
  },
  {
    type: "gist",
    content: "141c077fea34d4f178caa67ffdae2519"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Let's review what we did above, here are the things that we added:" +
      "<div class='lineBreakSmall' /> " +
      "1. First we imported the react-table component, a CSS file" +
      "<div class='lineBreakSmall' /> " +
      "2. Default table properties <code>ReactTableDefaults</code> are imported and declared. For now, we just have a " +
      "single property which just disabled the multi sort option, we will come to this later in sorting section" +
      "<div class='lineBreakSmall' /> " +
      "3. Added a method to <code>fetchData()</code>. This uses the same method <code>getUsers()</code> to fetch the data from the API from original source of the app" +
      "<div class='lineBreakSmall' /> " +
      "4. Additionally, the <code>fetchData()</code> calls <code>getTableDataFromJSONObject()</code> which just converts the data fetched from API into a format that react-table uses" +
      "<div class='lineBreakSmall' /> " +
      "5. Finally, added <code>ReactTable</code> component"
  },
  {
    type: "subheading",
    name: "add-react-table-data",
    title: "Add logic to convert data into table data in CommonFunctions.js"
  },
  {
    type: "gist",
    content: "dae37c2970eab0068ea9d04597aef597"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The method above simply returns 3 attributes:" +
      "<div class='lineBreakSmall' /> " +
      "1. <code>rows</code> - the table data in the form of rows" +
      "<div class='lineBreakSmall' /> " +
      "2. <code>pages</code> - the number of pages in the table" +
      "<div class='lineBreakSmall' /> " +
      "3. <code>totalRecords</code> - total records in the table"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "add-react-table-data",
    title: "Add CSS to decorate the table"
  },
  {
    type: "gist",
    content: "67a7503a7a429bcd296175019927a7b7"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Finally, you should see the table displayed as below, "
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-table.jpg"
  },
  {
    type: "subheading",
    name: "react-table-pagination",
    title: "Pagination"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "You dont have to worry about the pagination as long as you are returning your data in the above format (3 attributes explained above). " +
      "React Table will take care of the rest."
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "subheading",
    name: "react-table-sorting",
    title: "Sorting"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Sorting is taken care by React Table by default. It will pass the current sort column name in <code>sorted</code> property in <code>fetchData()</code>" +
      "This sorted property is actually an object that has the sort order as well in <code>desc</code> property. Once you receive this property, sort your table data accordingly and send back." +
      "<div class='lineBreak' /> " +
      "Check the <code>defaultSorted</code> property of <code>ReactTable</code> component for the default sorted column configuration" +
      "<div class='lineBreak' /> " +
      "By defult, react-table supports multi column sort, Use SHIFT + click on column to select multiple columns. Try this out and see. " +
      "Most of the times, we dont need this functionality. This is the exact reason why we have to disabled multi column sort in <code>ReactTableDefaults</code>. "
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/react-proxying-api-backend-apache-nginx-tomcat/",
    previousText:
      "React Proxy Backend API configuration - Apache Http Server, Nginx, Tomcat",
    nextURL: "/react-table-with-loading-spinner/",
    nextText: "React Table - How to add Loading Spinner"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/react-table.zip"
  }
];

class ReactTableWithPagination extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default ReactTableWithPagination;
