import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["reactTableWithRecordCount"],
  ...allHashTags,
  channel: "26"
};

const data = [
  {
    type: "toc",
    title: "React Table Series - Table of Contents",
    content:
      "<div class='lineBreak' /> " +
      "<h4><b>Part 1 : </b> <MyLink class='homelink' href='/how-to-create-react-table-with-pagination-and-sorting' target='_blank'>" +
      "React Table - How to create a simple react table with pagination and sorting</MyLink></h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 2 : </b> <MyLink class='homelink' href='/react-table-with-loading-spinner' target='_blank'>" +
      "React Table - How to add Loading Spinner</MyLink></h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 3 : </b> React Table - How to add a Record Count - [This article]</h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 4 : </b> <MyLink class='homelink' href='/react-table-with-nested-subtable' target='_blank'>" +
      "React Table - How to create a nested sub table</MyLink></h4>"
  },
  {
    type: "subheading",
    name: "react-table-with-record-count",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "In our previous article, we learned how to add a loading spinner to a React Table. " +
      "In this continuation article, we will show you how to add a record count bar with range of record on that page and total count of records in the table that we created here, " +
      "<MyLink class='homelink' href='/react-table-with-loading-spinner' target='_blank'>" +
      "React Table - How to add a loading spinner</MyLink>" +
      "<div class='lineBreakSmall' /> " +
      "Go to the <code>Download Source</code> section of the above tutorial and once you get access to the source, follow the below steps, " +
      "as we will be modifying some of the files in that app."
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "create-reusable-record-count-component",
    title: "Create resuable Record Count component"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Create a new file named <code>RecordCount.js</code> and add the code as below."
  },
  {
    type: "gist",
    content: "2fa18235b0498581e5cea261fcd4266f"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Let's see the importants aspects of the above code snippet:" +
      "<div class='lineBreakSmall' /> " +
      "1. <code>makeTable()</code> - This is the most important aspect. This will take care of everything. " +
      "This function is passed from the parent and provides access to the table attributes in RecordCount component" +
      "<div class='lineBreakSmall' /> " +
      "2. <code>recordsInfoText</code> - This text is displayed below the table. You can modify this as per your needs"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "add-record-count-to-react-table",
    title: "Add RecordCount component to React Table"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Now, open Dashboard.js and modify the code as below. This component " +
      "just fetches the data and displays as a table."
  },
  {
    type: "gist",
    content: "6c1c7fc7c1f0e46aab55b50febc5f359"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "The above code is exactly same as the original code in the previous article, " +
      "except for the numbered lines. " +
      "Let's see what are the differences at the highlighted line numbers:" +
      "<div class='lineBreakSmall' /> " +
      "1. First we imported the RecordCount component" +
      "<div class='lineBreakSmall' /> " +
      "2. Created a state variable for holding the total record count and initialized to null" +
      "<div class='lineBreakSmall' /> " +
      "3. Set the total record count when the data is received from the API response" +
      "<div class='lineBreakSmall' /> " +
      "4. Finally, add the RecordCount component where we wanted to show on the screen"
  },
  {
    type: "subheading",
    name: "add-css-record-count",
    title: "Add CSS to align RecordCount component"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "open index.css and modify the code as below."
  },
  {
    type: "gist",
    content: "32324e3f49baa2b1a25e17a4fa9ff637"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Thats all, Now, Open the browser and test the code, you should see a record count bar under the table as shown below. " +
      "Also, try to navigate using the pagination " +
      "buttons and see the counts getting changed on the fly."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-table-with-record-count.png"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/react-table-with-loading-spinner/",
    previousText: "React Table - How to add a loading spinner",
    nextURL: "/react-router-apache-nginx-tomcat/",
    nextText: "React Router Configuration - Apache Http Server, Nginx, Tomcat"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/react-table-total-record-count.zip"
  }
];

class ReactTableWithRecordCount extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default ReactTableWithRecordCount;
