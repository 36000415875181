import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["reactProxyApacheNginxTomcat"],
  ...allHashTags,
  channel: "18"
};

const data = [
  {
    type: "subheading",
    name: "react-proxying-api",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "You might have seen a lot of articles explaining how to proxy your backend APIs on " +
      "development environment. All those articles stop there and you need to struggle yourself " +
      "to get the same configuration working on your production. You often would have failed because same config would not work or " +
      "sometimes figured out yourself spending your weekend over just configuring the APIs. " +
      "We know this pain !! This is not just another article like others, so hang on." +
      "<div class='lineBreak' /> " +
      "Take a cup of coffee and enjoy reading through this simple step by step tutorial. " +
      "We promise, we will save your weekend from getting ruined :)" +
      "<div class='lineBreak' /> " +
      "In this tutorial, We will explain you how to configure your backend APIs on both development and production environments on " +
      "three most famous servers: <b>Apache HTTP Server, Nginx and Tomcat</b>."
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "create-react-app-cra-configure",
    title: "Configuring on Create-React-App (CRA)"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "We have configured react router and API Proxy configured on Create React App (CRA) scaffolding application. " +
      "If you are struggling to configure React Router in your application, refer to this easy step by step tutorial. " +
      "<MyLink class='homelink' href='/create-react-app-react-router' target='_blank'><span>Create React App - React Router Setup</span></MyLink>. " +
      "We used the same application configured in this tutorial and extended it to configure the API Proxy." +
      "<div class='lineBreak' /> " +
      "First we need to access a REST API. For this we will be using axios library, so go ahead and install it." +
      "<div class='lineBreakSmall' /> " +
      "<code>npm install axios</code>" +
      "<div class='lineBreakSmall' /> " +
      "Now, create a component called Dashboard and add the code as below. This component will just fetches the data and displays as a table."
  },
  {
    type: "gist",
    content: "9c4c6f2137de0811ebfc1b176784c6f7"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Create a helper class that has the actual axios call to the API,"
  },
  {
    type: "gist",
    content: "117ed2aae88925adda54015c615cc260"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Thats all for the application. We will now see how to configure this API endpoint URL and make our application working. " +
      "You can find source of the working application at the end. Check &quot;Download Source&quot; section."
  },
  {
    type: "subheading",
    name: "proxy-configuration-on-development",
    title: "Proxy Configuration on Development:"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "Configuring API proxy in development environment is dependent on your CRA version. " +
      "You can find your version in <code>package.json</code>. Check version of <code>react-scripts</code>."
  },
  {
    type: "text",
    content: "<h4>1. For react-scripts version 0.2.3 and higher :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "We can configure proxy for these versions in <code>package.json</code> itself using <code>proxy</code> property. " +
      "<code>changeOrigin</code> is used if domains are different. Otherwise you might get a CORS error. " +
      "Finally, You can add all your headers, if any, under <code>headers</code> property."
  },
  {
    type: "gist",
    content: "7445f469886d13825548bfc900fe4f35"
  },
  {
    type: "mediaAd",
    position: "middle1"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>2. For react-scripts version 2.0.0 and higher :</h4>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "For these versions, apart from the above <code>proxy</code> property option, we can now also move all proxy configurations into a separate file named <code>setupProxy.js</code>. " +
      "Note that although <code>proxy</code> property works, it can accept only a string. If its an object use <code>setupProxy.js</code> instead. " +
      "To recognize this file, we need to install an npm module as :" +
      "<div class='lineBreakSmall' /> " +
      "<code>npm install http-proxy-middleware</code>" +
      "<div class='lineBreakSmall' /> " +
      "Create a file named <code>setupProxy.js</code> in src folder and add your proxy configurations in it."
  },
  {
    type: "gist",
    content: "2922002774d08dbc0c2c197f260aaf4c"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "You can also add your headers either using <code>headers</code> property or using a function called on the event <code>onProxyReq</code>." +
      "<div class='lineBreak' /> " +
      "This is all about Development configuration. Now lets look at Production configuration. " +
      "Before that, lets create a production build using, " +
      "<div class='lineBreakSmall' /> " +
      "<code>npm run build</code>" +
      "<div class='lineBreakSmall' /> " +
      "This build will be deployed to the servers in the following sections."
  },
  {
    type: "subheading",
    name: "proxy-configuration-on-production",
    title: "Proxy Configuration on Production:"
  },
  {
    type: "text",
    content:
      "Now, let us see how to configure the API proxy on production servers. " +
      "Apache and Nginx configurations are straight forward. Tomcat configuration " +
      "is a bit different. We will see how, when we discuss about it."
  },
  {
    type: "subheading",
    name: "apache-http-server",
    title: "Apache HTTP Server - 2.4.43"
  },
  {
    type: "text",
    content:
      "<h4>1. Enable the following modules in <code>httpd.conf</code> :</h4>"
  },
  {
    type: "text",
    content:
      "<code>LoadModule proxy_module modules/mod_proxy.so</code><br/>" +
      "<code>LoadModule proxy_http_module modules/mod_proxy_http.so</code><br/>" +
      "<code>LoadModule ssl_module modules/mod_ssl.so</code> - If not already enabled <br/>" +
      "<code>LoadModule rewrite_module modules/mod_rewrite.so</code>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "<h4>2. Add the following configuration in <code>httpd.conf</code> file</h4>"
  },
  {
    type: "gist",
    content: "6dec0cc994631ed347285059dd559075"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "As you can see, the Directory tag takes care of the server side routing and ProxyPass directives takes care of the API proxying. " +
      "If you are not sure what this is, head over to this tutorial " +
      "<MyLink class='homelink' href='/react-router-apache-nginx-tomcat' target='_blank'><span>React Router Configuration - Apache Http Server, Nginx, Tomcat</span></MyLink>" +
      "<div class='lineBreakSmall' /> " +
      "Now, navigate to the home page of <code>/react-proxy-config</code> and make sure you are seeing the data from your API."
  },
  {
    type: "text",
    content: "<div class='lineBreak' /> "
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-proxy-dashboard.jpg"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "nginx",
    title: "Nginx - 1.16.1"
  },
  {
    type: "text",
    content:
      "<h4>1. Add the following in <code>nginx.conf</code> :</h4>" +
      "<div class='lineBreak' /> " +
      "The first section is used for proxying the API while the second section is used for server side routing."
  },
  {
    type: "gist",
    content: "55404668f9130f56b57589ca7d8d3635"
  },
  {
    type: "subheading",
    name: "tomcat",
    title: "Tomcat - 10.0.0"
  },
  {
    type: "text",
    content:
      "Tomcat configuration is a bit different than other two servers. Tomcat cannot proxy requests on its own. It can only Rewrite the URLs. " +
      "So, we will take help of Apache Http Server and setup a Reverse Proxy Configuration. " +
      "<div class='lineBreak' />" +
      "Deploy your application to <code>webapps</code> folder of tomcat and add the below configuration in Apache <code>httpd.conf</code> file." +
      "<div class='lineBreak' />"
  },
  {
    type: "gist",
    content: "d6c089f99344724f9d1ca89790e8ec63"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />" +
      "Remember, You need to navigate to your apache URL and check if its properly redirected to the Tomcat. " +
      "<div class='lineBreak' />" +
      "<code>http://localhost/react-proxy-config/</code>" +
      "<div class='lineBreakSmall' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/react-router-apache-nginx-tomcat/",
    previousText:
      "React Router Configuration - Apache Http Server, Nginx, Tomcat",
    nextURL: "/react-widgetpack-free-disqus-alternative/",
    nextText:
      "React WidgetPack Comments - A Free Disqus alternative - Step by Step"
  },
  {
    type: "source",
    url:
      "https://github.com/learninjavagithub/sourcecode/raw/master/react-proxy-config.zip"
  }
];

class ReactProxyConfig extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />
      </>
    );
  }
}

export default ReactProxyConfig;
